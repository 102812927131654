<template>
	<div class="d-flex">
		<ThumbnailTray
			ref="thumbnails"
			:artifacts="recordingList"
			:artifact-type="'videos'"
			:is-view-only="isViewOnly"
			:parent-id="parentId"
			:has-free-trial-ended="hasFreeTrialEnded"
			@toggle-gallery="updateShowThumbs"
			@download-artifacts="downloadArtifacts"
			@delete-artifacts="deleteArtifacts"
			@select-item="setCurrentRecordingId" />

		<div
			class="d-flex flex-column"
			style="background-color: #f3f5f9; height: 100vh; flex: 1"
			:style="{
				display: isMobile && showThumbs ? 'none !important' : 'flex',
			}">
			<div class="d-flex align-center justify-space-between" v-if="isDesktop">
				<div>
					<v-btn
						fab
						small
						class="close-gallery ml-2"
						color="black"
						style="color: #fff"
						@click="toggleGallery">
						<v-icon>icon-grid-3x3</v-icon>
					</v-btn>
				</div>
				<div style="height: 72px" class="d-flex flex-row justify-center align-center">
					<div>
						<v-btn v-if="this.$vuetify.breakpoint.smAndDown" elevation="0" icon @click="startComment">
							<v-icon>icon-chat-stroke</v-icon>
						</v-btn>
					</div>
					<div v-if="recordingReady">
						<v-tooltip bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="share(currentRecordingId)">
									<v-icon>icon-link-double</v-icon>
								</v-btn>
							</template>
							<span>Copy Link</span>
						</v-tooltip>
					</div>
					<div v-show="recordingReady">
						<v-tooltip bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="download">
									<v-icon>icon-download</v-icon>
								</v-btn>
							</template>
							<span>Download</span>
						</v-tooltip>
					</div>
					<div>
						<v-tooltip bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-if="!(isViewOnly && !hasFreeTrialEnded)"
									elevation="0"
									icon
									v-bind="attrs"
									v-on="on"
									@click="() => deleteRecording(currentRecording)">
									<v-icon color="red">icon-trash-full-stroke</v-icon>
								</v-btn>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</div>
				</div>
				<v-btn fab small class="mr-2" color="black" style="color: #fff" :ripple="false" @click="closeLightBox">
					<v-icon>icon-close-small</v-icon>
				</v-btn>
			</div>
			<div v-else class="d-flex align-center justify-space-between pa-4">
				<div>
					<v-btn
						fab
						small
						class="close-gallery ml-2"
						color="black"
						style="color: #fff"
						@click="toggleGallery">
						<v-icon>icon-grid-3x3</v-icon>
					</v-btn>
				</div>
				<v-btn icon x-large color="black" style="color: #fff" :ripple="false" @click="closeLightBox">
					<v-icon>icon-close-small</v-icon>
				</v-btn>
			</div>

			<!-- TODO: MK - Need to figure out a better way to do top bars for mobile without using so many hardcoded heights -->
			<div
				class="d-flex flex-column"
				:class="{ 'justify-center': isDesktop }"
				:style="{
					display: isMobile && showThumbs ? 'none !important' : 'flex',
					height: isMobile ? '100%' : 'calc(100% - 72px)',
				}">
				<div style="height: 100%" class="d-flex flex-column align-center">
					<v-carousel
						class="recording-carousel"
						:class="{ 'mobile-recording-carousel': isMobile }"
						:style="{ display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex' }"
						light
						:hide-delimiters="true"
						hide-delimiter-background
						delimiter-icon="icon-round-solid"
						:show-arrows="recordingList.length > 1"
						:height="isDesktop ? '100%' : ''"
						v-model="currentRecordingId"
						@change="recordingChanged">
						<v-carousel-item
							v-for="(recording, i) in recordingList"
							v-bind:key="recording.id"
							:eager="true"
							:value="recording.id">
							<div
								class="recording-item d-flex align-center flex-column"
								:class="{ 'justify-center': isDesktop }"
								style="width: 100%; height: 100%">
								<!--Processing recording, please wait.-->
								<div
									class="d-flex flex-row justify-center"
									:style="{
										'min-height': isMobile ? '100%' : '80%',
										width: isMobile && !isLandscape ? '100%' : '90%',
										'max-height': isMobile ? '100%' : '80%',
									}"
									:class="{ 'pl-4': isMobile, 'pr-4': isMobile }">
									<div
										class="d-flex video-processing flex-column"
										:style="{
											width: isMobile ? '100%' : '80%',
											height: '100%',
											display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex',
										}"
										v-if="recording.recordingStatus !== 'READY'">
										<div class="icon">
											<img
												:src="require('@/assets/liveswitch-v-sign-hand-emoji.svg')"
												alt="Processing" />
										</div>
										<h5 class="message">This video is still processing...</h5>
										<div class="d-flex refresh">
											<v-btn rounded elevation="0" @click="loadRecordings">
												<v-icon>icon-refresh</v-icon>
												Refresh
											</v-btn>
										</div>
									</div>

									<!--Video-->
									<div
										v-else
										id="video-container"
										class="d-flex"
										:style="{
											width: isMobile ? '90%' : '65%',
											flex: 1,
										}">
										<VideoPlayer
											:content-class="
												currentRecordingId === recording.id ? 'current-recording' : ''
											"
											:style="{ borderRadius: isMobile ? '12px' : '12px 0 0 0' }"
											:video-id="videoRecordingId(recording.id)"
											:recording-id="recording.id"
											:source="recording.recordingUrl"
											:can-snapshot="true"
											:should-pause="
												currentRecordingId === null ||
												currentRecordingId !== recording.id ||
												commenting
											"
											:filename="videoFileName(recording)"
											@take-snapshot="takeSnapshot" />
									</div>
									<!-- TODO: Find a better solution for the 1px margin. The black background from the left container is showing and looks like a border -->
									<div
										v-if="isDesktop && currentRecordingId"
										class="comment-controls"
										style="margin-left: 1px; width: 25%">
										<v-card class="desktop-right">
											<!-- <v-icon @click="startComment">icon-close-circle-solid</v-icon> -->
											<CommentBox
												class="pl-2 pr-2 pt-4"
												:parent-id="currentRecordingId"
												:parent-type="'video'"
												:has-free-trial-ended="hasFreeTrialEnded"
												:conversation-id="currentRecording?.conversationId"
												:light-box-view="true"
												:project-id="projectId"
												:users="localUsers"
												:me="localMe"
												:is-view-only="isViewOnly"
												@toggle-reply-box="toggleReplyBox"
												@close-comment-box="startComment" />
										</v-card>
									</div>
								</div>

								<div
									class="d-flex flex-column pa-4"
									style="
										width: 90%;
										background-color: #fff;
										border-bottom-left-radius: 12px;
										border-bottom-right-radius: 12px;
									">
									<div v-if="shouldShowIncompleteBanner(recording)">
										<v-alert color="error" outlined border="left" icon="icon-triangle-warning">
											<span v-if="recording.customerId">
												This recording is incomplete. Please have your customer reopen the
												recording link.
											</span>
											<span v-else>
												It looks like your recording didn’t finish uploading. Click the Start
												Recording button again to continue the upload
											</span>
										</v-alert>
									</div>
									<div class="d-flex mt-2" style="color: rgba(0, 0, 0, 0.6)">
										{{ recordingDate }}
									</div>
								</div>
							</div>
						</v-carousel-item>
					</v-carousel>

					<!-- Mobile Controls -->

					<div
						class="d-flex flex-column"
						:style="{ width: isMobile && isLandscape ? '90%' : '100%' }"
						v-if="isMobile">
						<div
							class="d-flex flex-column justify-space-between pl-4 pr-4 mobile-controls"
							:style="{
								display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex',
							}">
							<div class="d-flex flex-row justify-space-between align-space-between">
								<div class="d-flex flex-row">
									<div>
										<v-btn elevation="0" large icon @click="startComment">
											<v-icon>icon-chat-stroke</v-icon>
										</v-btn>
									</div>
									<div v-if="recordingReady">
										<v-tooltip bottom content-class="lightbox-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													elevation="0"
													large
													icon
													v-bind="attrs"
													v-on="on"
													@click="share(currentRecordingId)">
													<v-icon>icon-link-double</v-icon>
												</v-btn>
											</template>
											<span>Copy link to video</span>
										</v-tooltip>
									</div>
								</div>
								<div class="d-flex flex-row">
									<div v-if="recordingReady">
										<v-tooltip bottom content-class="lightbox-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													elevation="0"
													large
													icon
													v-bind="attrs"
													v-on="on"
													@click="download">
													<v-icon>icon-download</v-icon>
												</v-btn>
											</template>
											<span>Download</span>
										</v-tooltip>
									</div>
									<div v-if="!isViewOnly">
										<v-tooltip bottom content-class="lightbox-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													elevation="0"
													large
													icon
													v-bind="attrs"
													v-on="on"
													@click="() => deleteRecording(currentRecording)">
													<v-icon color="red">icon-trash-full-stroke</v-icon>
												</v-btn>
											</template>
											<span>Delete video</span>
										</v-tooltip>
									</div>
								</div>
							</div>
						</div>
						<div
							class="d-flex flex-column"
							:class="{
								'pl-4': !isMobile || !isLandscape,
								'pr-4': !isMobile || !isLandscape,
								'px-4': isMobile && isLandscape,
							}"
							:style="{
								backgroundColor: !isMobile ? '#fff' : 'transparent',
								width: isMobile && !isLandscape ? '100%' : '100%',
								borderRadius: isMobile ? '12px' : '0px 0px 12px 12px',
								display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex',
							}">
							<div class="d-flex" style="color: rgba(0, 0, 0, 0.6)">
								{{ recordingDate }}
							</div>
						</div>
					</div>
					<!-- TODO: Fix/restyle/restructure comments to avoid using VW for width if possible -->
					<div v-if="isMobile && currentRecordingId" v-show="isCommentBoxOpen" class="comment-controls">
						<v-card class="desktop-right">
							<!-- <v-icon @click="startComment">icon-close-circle-solid</v-icon> -->
							<CommentBox
								class="pl-2 pr-2 pt-4"
								:parent-id="currentRecordingId"
								:parent-type="'video'"
								:has-free-trial-ended="hasFreeTrialEnded"
								:conversation-id="currentRecording?.conversationId"
								:light-box-view="true"
								:project-id="projectId"
								:is-view-only="isViewOnly"
								:users="localUsers"
								:me="localMe"
								@toggle-reply-box="toggleReplyBox"
								@close-comment-box="startComment" />
						</v-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ThumbnailTray from "./ThumbnailTray.vue";
	import api from "../api";
	import CommentBox from "./CommentBox.vue";
	import timezones from "../helpers/timezones";
	import { getSeededAvatarColor, getAgentNameInitials } from "../helpers/utilities";
	import { downloadFile, generateFileNameFromUrl } from "../helpers/download";
	import { ArtifactType } from "@/enums/ArtifactType";
	import { blurActiveElement } from "../helpers/utilities";

	export default {
		components: {
			CommentBox,
			ThumbnailTray,
		},
		props: {
			recordings: {
				type: Array,
				required: true,
			},
			bulkSelect: {
				type: Boolean,
				required: false,
			},
			lightboxId: {
				type: String,
				required: false,
			},
			showGallery: {
				type: Boolean,
				default: false,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			parentId: {
				type: String,
				required: true,
			},
			projectId: {
				type: String,
				required: false,
			},
			user: {
				type: Object,
				required: false,
				default: {},
			},
			openLightBox: {
				type: Function,
				required: true,
			},
			users: {
				type: Array,
				default: () => [],
				required: false,
			},
			me: {
				type: Object,
				default: null,
				required: false,
			},
			hasFreeTrialEnded: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				areCustomControlSupported: false,
				canPictureInPicture: true,
				canPlay: false,
				currentPlaybackSpeed: 1,
				duration: 0,
				figureId: null,
				isDownloading: false,
				isFullScreen: false,
				isMuted: false,
				isPlaying: false,
				isScrubbing: false,
				maxSliderValue: 100,
				percent: 0,
				playPromise: undefined,
				progress: 0,
				showMoreControls: false,
				showSpeedControls: false,
				wasPlaying: false,
				waiting: false,
				saving: false,
				editNotes: false,
				recordingNotes: "",
				recordingList: [],
				canSaveNotes: false,
				lightBoxIndex: null,
				lightBoxOpen: false,
				cropping: false,
				annotating: false,
				savingAnnotation: false,
				savingCrop: false,
				isTouch: false,
				dirty: false,
				commenting: false,
				isLightboxThumbsOpen: false,
				isUnauthenticated: false,
				backgroundImage: "",
				filename: String,
				source: String,
				videoId: String,
				disablePlayback: Boolean,
				sourceCommentWidth: -1,
				backgroundImageCaption: {
					title: "",
					description: "",
				},
				video: {
					muted: false,
					paused: false,
					ended: false,
					currentTime: 0,
				},
				isDesktop: this.$vuetify.breakpoint.mdAndUp,
				commentBoxStyle: {},
				conversation: null,
				videoContainer: null,
				showButtons: false,
				currentRecordingId: "",
				currentRecording: null,
				localUsers: [],
				localMe: {},
				showThumbs: false,
				isMobile: this.$vuetify.breakpoint.smAndDown,
				isLandscape: window.matchMedia("(orientation: landscape)").matches,
				previousRecordingId: null,
				previousRecordingIds: [],
			};
		},
		async mounted() {
			window.addEventListener("resize", this.handleResize);
			if (this.$vuetify.breakpoint.mdAndUp) {
				//this.$refs.lightbox.showButtons = () => {};
				window.addEventListener("mousemove", this.handleMouseMove);
			}

			this.mapRecordingList(this.recordings);
			this.currentRecording = this.recordingList.length > 0 ? this.recordingList[0] : null;

			if (this.currentRecording) {
				this.currentRecordingId = this.currentRecording.id;
			}

			const usersPromise = this.setUsers;
			const mePromise = async () => {
				if (!this.me) {
					this.localMe = await api.getMe();
				} else {
					this.localMe = this.me;
				}
			};

			await Promise.all([usersPromise(), mePromise()]);
		},
		computed: {
			isCommentBoxOpen() {
				return this.$vuetify.breakpoint.mdAndUp || this.commenting;
			},
			isThumbsOpen() {
				return this.$refs.thumbnails?.isOpen();
			},
			recordingReady() {
				return this.currentRecording?.recordingStatus === "READY";
			},
			recordingDate() {
				return timezones.formatDate(this.currentRecording?.recordingStart);
			},
			actionClass() {
				if (this.cropping) {
					return "cropping";
				} else if (this.annotating) {
					return "annotating";
				} else {
					return "comment";
				}
			},
		},
		methods: {
			async setUsers() {
				if (this.users.length == 0) {
					const localUsersResponse = await api.getUsers({ pageSize: 1000 });
					this.localUsers = localUsersResponse.rows;
				} else {
					this.localUsers = this.users;
				}
			},
			closeLightBox() {
				this.commenting = false;
				this.currentRecording = null;
				this.currentRecordingId = null;
				if (this.showThumbs) {
					this.toggleGallery();
				}
				this.$emit("close-lightbox");
			},
			updateShowThumbs(showThumbs) {
				this.showThumbs = showThumbs;
			},
			toggleGallery() {
				this.$refs.thumbnails.toggleGallery();
				this.showThumbs = this.$refs.thumbnails.isOpen();
				blurActiveElement();
			},
			updateLightboxIndex(artifactId) {
				this.openLightBox(artifactId);
			},
			downloadArtifacts(artifactIds) {
				for (const artifactId of artifactIds) {
					this.download(null, artifactId);
				}
			},
			deleteArtifacts(artifactIds, callback) {
				this.previousRecordingIds = this.recordingList.map((x) => x.id);
				this.previousRecordingId = this.currentRecordingId;
				const updatedCallBack = (deleted) => {
					if (deleted) {
						if (artifactIds.find((x) => x === this.previousRecordingId)) {
							for (let i = 0; i < artifactIds.length; i++) {
								if (artifactIds[i] !== this.previousRecordingId) {
									this.previousRecordingIds = this.previousRecordingIds.filter(
										(x) => x !== artifactIds[i]
									);
								}
							}
							let index = this.previousRecordingIds.findIndex((x) => x === this.previousRecordingId);
							this.previousRecordingIds.splice(index, 1);
							this.recordingList.splice(index, 1);

							if (index > 0) {
								index--;
							}

							if (this.previousRecordingIds.length > 0 && this.recordingList.length > 0) {
								this.currentRecordingId = this.recordingList[index].id;
								this.currentRecording = this.recordingList[index];
							}

							this.previousRecordingIds = [];
							this.previousRecordingId = null;
						}

						if (callback) {
							callback();
						}
					}
				};
				this.$emit("delete-artifacts", "recordings", artifactIds, updatedCallBack);
			},
			takeSnapshot(videoElemtent, recordingId) {
				this.$emit("take-snapshot", videoElemtent, recordingId);
			},
			async loadRecordings() {
				this.recordingsLoading = true;
				let recordings;
				if (!this.projectId) {
					recordings = await api.getRecordings(this.recordingList[0]?.conversationId);
				} else {
					const resp = await api.getArtifactsForProjectInsecure(this.projectId, ArtifactType.Recordings);
					const result = resp.artifacts;
					this.recordingConversations = resp.conversations;
					recordings = result?.sort((a, b) => (a.recordingStart > b.recordingStart ? 1 : -1));
				}
				this.mapRecordingList(
					recordings?.sort((a, b) => new Date(b.recordingStart) - new Date(a.recordingStart))
				);
				this.currentRecording = this.recordingList.find((x) => x.id == this.currentRecordingId);
				this.currentRecordingId = this.currentRecording?.id;
			},
			shouldShowIncompleteBanner(recording) {
				if (!recording) {
					return true;
				}
				if (recording.meetingId) {
					return false;
				}
				const show = !recording.isUploadComplete;
				return show;
			},
			mapRecordingList(recordings) {
				this.recordingList = recordings.map((r) => {
					let recordingUrl = r.recordingUrl;

					if (recordingUrl.indexOf("?") === -1) {
						recordingUrl = recordingUrl + "?ext=.mp4";
					} else {
						recordingUrl = recordingUrl + "&ext=.mp4";
					}
					return {
						...r,
						title: timezones.formatDate(r.recordingStart),
						recordingUrl: recordingUrl,
						mediaType: "webVideo",
						ext: "mp4",
					};
				});
			},
			getUserAvatarBackgroundColor(item) {
				return getSeededAvatarColor(item?.agentName) + " !important";
			},
			getUserInitials() {
				return getAgentNameInitials(this.user?.name) ?? "-";
			},
			handleMouseMove() {
				if (this.showButtons) {
					return;
				}
				this.showButtons = true;
				setTimeout(() => {
					this.showButtons = false;
				}, 1000);
			},
			handleResize() {
				this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
				if (this.$refs.videoLightbox) {
					this.$refs.videoLightbox.showThumbs = false;
				}
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			setCurrentRecordingId(id) {
				this.currentRecording = this.recordingList.find((x) => x.id == id);
				this.currentRecordingId = id;
			},
			toggleReplyBox(isOpen) {},
			recordingChanged(id) {
				if (!id) {
					return;
				}

				this.currentRecording = this.recordingList.find((x) => x.id == id);
			},
			async share(recordingId) {
				this.$emit("share", recordingId);
			},
			startComment() {
				this.commenting = !this.commenting;
			},
			hideThumbnailTray() {
				this.$refs.lightbox.showThumbs = false;
			},
			getRecordingId() {
				return this.recordingList[this.lightBoxIndex]?.id;
			},
			async saveNotes(recordingNotes) {
				const recording = this.recordingList[this.lightBoxIndex];

				let request = { notes: recordingNotes };
				await api.updateRecording(recording.id, request);

				recording.description = recordingNotes;
				this.editNotes = false;
				this.recordingNotes = "";
				this.canSaveNotes = false;
			},
			onNotesChanged(notes) {
				const recording = this.recordingList[this.lightBoxIndex];
				this.canSaveNotes = notes != recording.description;
			},
			lightBoxClose() {
				this.editNotes = false;
				this.recordingNotes = "";
				this.canSaveNotes = false;
				this.lightBoxOpen = false;
				this.lightBoxIndex = null;
			},
			async download(e, recordingId = null) {
				try {
					if (e) {
						e.preventDefault();
					}
					const me = this;
					let video = this.currentRecording;
					if (recordingId) {
						video = this.recordingList.find((r) => r.id === recordingId);
					}
					this.isDownloading = true;
					downloadFile(video.recordingUrl, null);
					return;
				} catch (error) {
					console.error(error);
				}
			},
			videoFileName(recording) {
				return generateFileNameFromUrl(recording.recordingUrl);
			},
			editRecording() {
				const recording = this.recordingList[this.lightBoxIndex];
				this.recordingNotes = recording.description;
				this.editNotes = true;
			},
			async deleteRecording(currentRecording) {
				let index = this.recordingList.findIndex((r) => r.id === currentRecording.id);

				if (await this.$root.$confirm.deleteOpen("Delete Video", `This will delete the video.`)) {
					await api.deleteRecording(currentRecording?.conversationId, currentRecording.id);
					this.$emit("delete-recording", currentRecording.id);
					this.recordingList.splice(index, 1);

					if (this.recordingList.length > 0) {
						if (index > 0) {
							index -= 1;
						}

						this.currentRecording = this.recordingList[index];
						this.currentRecordingId = this.currentRecording.id;
					} else {
						this.$emit("close-lightbox");
					}
				}
			},
		},
		watch: {
			showButtons: {
				handler(newVal) {},
			},
			users: {
				handler(newVal) {
					this.setUsers();
				},
				deep: true,
			},
			recordings: {
				handler(newVal) {
					const recordings = [...newVal].sort(
						(a, b) => new Date(b.recordingStart) - new Date(a.recordingStart)
					);
					this.recordingList = recordings.map((r) => {
						let recordingUrl = r.recordingUrl;
						if (recordingUrl.indexOf("?") === -1) {
							recordingUrl = recordingUrl + "?ext=.mp4";
						} else {
							recordingUrl = recordingUrl + "&ext=.mp4";
						}
						return {
							...r,
							title: timezones.formatDate(r.recordingStart),
							recordingUrl: recordingUrl,
							mediaType: "webVideo",
							ext: "mp4",
						};
					});

					if (this.recordingList.length === 0) {
						this.closeLightBox();
					}
				},
				deep: true,
			},
			isLightboxThumbsOpen: {
				handler(newVal) {
					this.$emit("update:isLightboxThumbsOpen", newVal);
				},
			},
			lightboxId: {
				handler(newVal) {
					this.openLightBox(newVal);
				},
			},
		},
	};
</script>

<style scoped>
	:deep(.v-dialog--fullscreen) {
		overflow-y: hidden !important;
	}
	.v-dialog__content .v-dialog {
		overflow-y: hidden !important;
	}
	.v-dialog.v-dialog--fullscreen {
		overflow-y: hidden !important;
	}
	:deep(.v-dialog__content .v-dialog) {
		overflow-y: hidden !important;
	}
	:deep(.v-window__container) {
		width: 100%;
	}
	.team-avatar {
		display: flex;
		justify-content: center;
		font-size: 1rem;
		color: #fff;
		text-transform: uppercase;
	}

	@media (max-width: 415px) {
		.v-btn--icon.v-size--default {
			width: 32px;
			height: 32px;
		}
	}

	@media (max-width: 959px) {
		.video-processing {
			border-radius: 12px;
		}
		:deep(.v-window__prev),
		:deep(.v-window__next) {
			top: 70%;
			position: fixed;
		}
		.v-btn--icon.v-size--default {
			/* width: 24px;
			height: 24px;
			margin: 0 1px; */
		}

		.v-btn--icon.v-size--default .v-icon {
			font-size: 1.25rem;
		}
	}
	.video-buttons-lightbox button {
		z-index: inherit !important;
	}
	.video-buttons-lightbox {
		position: fixed;
		top: 350px;
		right: 0px;
		padding-left: 16px;
		padding-right: 16px;
		z-index: 10000;
	}
	.video-slider-lightbox {
		position: fixed;
		top: 320px;
		right: 0px;
		padding-left: 16px;
		padding-right: 16px;
		z-index: 10000;
	}

	.comment-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		pointer-events: none;
	}

	.add-comment-card {
		position: relative;
		bottom: 0;
	}
	.lightbox-controls {
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 10000;
		left: 0%;
	}

	.lightbox-owner {
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 10000;
		left: 25%;
		color: #000;
	}

	.theme--light.v-btn.v-btn--icon {
		color: #000;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon {
		color: #000;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		margin: 10px 3px;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon:last-child {
		color: #ec5d43;
	}

	.lightbox-tooltip {
		font-size: 10px;
		line-height: 12px;
		padding: 5px 8px;
	}

	.recordings.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10010;
		width: 280px;
		height: 100vh;
		overflow-y: scroll;
	}

	.recordings.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.recordings.fixed > .recording-controls {
		flex-direction: column;
	}

	.recordings.fixed > .recording-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.recordings.fixed > .recording-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	.mobile-recording-carousel {
		height: 50%;
		/* margin-bottom: 100px; TODO: Remove? */
	}

	:deep(.mobile-recording-carousel .v-window__container) {
		height: 100%;
	}

	:deep(.mobile-recording-carousel .v-window__container .v-carousel__item) {
		height: 100%;
	}

	.desktop-right {
		overflow-y: scroll;
		box-shadow: unset !important;
	}

	.lightbox-controls .theme--light.v-btn.v-btn--icon {
		color: #000 !important;
	}

	.theme--light.v-btn.v-btn--icon {
		color: #000;
	}

	.mobile-controls {
		width: 100%;
	}

	@media (min-width: 960px) {
		.video-slider-lightbox {
			padding-left: 8px !important;
		}

		.lightbox-controls {
			left: 5%;
			width: 90%;
			top: 10px;
		}

		.desktop-right {
			overflow-y: auto;
			border-radius: 0px 12px 0px 0px !important;
			filter: unset !important;
			height: 100%;
			border-left: #fff;
		}

		.comment-controls {
			width: 350px !important;
			right: 6% !important;
		}

		.comment-box {
			max-height: 100% !important;
		}
	}

	.comment-controls {
		/* position: fixed; */
		width: 90%;
		height: 100%;
		/* height: 75vh;
		top: 11vh;
		right: 3%; */
		display: flex;
		flex-direction: column;
		z-index: 10000;
	}

	.comment-controls .v-overlay {
		z-index: 10005;
		margin: 32px;
		border-radius: 12px;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		.video-slider-lightbox {
			top: 35%;
		}

		@media (orientation: landscape) {
			:deep(.v-window__prev),
			:deep(.v-window__next) {
				top: 65%;
				position: fixed;
			}
			.mobile-recording-carousel {
				/* margin-bottom: 50px !important; // TODO: Remove? */
			}
			.video-slider-lightbox {
				top: 35% !important;
			}
			.video-buttons-lightbox {
				top: 42% !important;
			}

			.lightbox-controls {
				top: 50% !important;
			}
		}

		.video-buttons-lightbox {
			top: 38%;
		}

		.lightbox-controls {
			justify-content: space-between;
			top: 42%;
			padding-left: 16px;
			padding-right: 16px;
		}

		.lightbox-owner {
			justify-content: space-between;
			top: 2%;
		}

		.desktop-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			padding: 0 !important;
			border-radius: 0px 0px 0px 0px !important;
		}

		html {
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			max-width: 100vw !important;
			overflow: hidden !important;
			overflow-y: auto !important;
		}
		html ::-webkit-scrollbar {
			display: none;
		}
		.comment-controls {
			width: 100vw;
			right: 0;
			/* height: 85vh; TODO: Don't hardcode heights. This does not work well on mobile. Fill up containers using flex box */
			top: 10vh;
		}

		.comment-controls .v-overlay {
			margin: 10px !important;
		}

		.recordings.fixed {
			width: 100%;
			z-index: 10009;
		}
	}
</style>
