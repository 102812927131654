<template>
	<div class="full-width" v-if="showArtifactView.length > 0">
		<ArtifactPage
			v-if="this.$vuetify.breakpoint.smAndDown"
			:recordings="recordings"
			:snapshots="snapshots"
			:files="files"
			:parent-id="project.id"
			:project-id="project.id"
			:project-name ="project.name"
			:is-view-only="isViewOnly"
			:create-snapshot="createSnapshot"
			:open-video-light-box="openVideoLightBox"
			:artifact-type="showArtifactView"
			:has-free-trial-ended="hasFreeTrialEnded"
			:share="share"
			:delete-snapshot="deleteSnapshot"
			:create-video-snapshot="createVideoSnapshot"
			:user="projectUser"
			:users="teamMembers"
			@delete-recording="checkBulkSelectRecordings"
			@close="showArtifactView = ''"
			@delete-file="deleteFile"
			@delete="deleteArtifacts"
			@download-artifacts="downloadSelected">
		</ArtifactPage>
		<v-card class="border-topleft" v-else>
			<ArtifactPage
				:recordings="recordings"
				:has-free-trial-ended="hasFreeTrialEnded"
				:snapshots="snapshots"
				:files="files"
				:parent-id="project.id"
				:project-id="project.id"
				:project-name ="project.name"
				:share="share"
				:open-video-light-box="openVideoLightBox"
				:is-view-only="isViewOnly"
				:create-snapshot="createSnapshot"
				:artifact-type="showArtifactView"
				:delete-snapshot="deleteSnapshot"
				:user="projectUser"
				:users="teamMembers"
				:create-video-snapshot="createVideoSnapshot"
				@delete-recording="checkBulkSelectRecordings"
				@close="showArtifactView = ''"
				@delete-file="deleteFile"
				@delete="deleteArtifacts"
				@download-artifacts="downloadSelected">
			</ArtifactPage>
		</v-card>
	</div>
	<div
		class="width-100"
		:class="!isAuthenticated ? 'is_unauthenticated' : ''"
		v-else-if="this.$vuetify.breakpoint.smAndDown">
		<div class="d-flex flex-column project-page">
			<v-btn @click="reset" width="75px" elevation="0" color="#f5f5f5" background-color="#fff" v-test="'project-back'">
				<v-icon>icon-chevron-left</v-icon>
				Back
			</v-btn>
			<div class="d-flex justify-space-between mb-4">
				<div class="number-container">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<span v-if="tab != 2" class="date" v-bind="attrs" v-on="on" v-test="'project-created-date'"
								>Created {{ project.createdAt | formatDateOnlyLong }}
								{{ project.createdAt | formatTimeOnly }}</span
							>
							<span v-else class="date" v-bind="attrs" v-on="on" v-test="'project-created-date'">
								{{ project.createdAt | formatDateOnlyShort }}
							</span>
						</template>
						<span>
							{{ project.createdAt | formatDate }}
						</span>
					</v-tooltip>
				</div>
				<div class="d-flex flex-row status-pill" :class="getStatusClass()">
					{{ beautifyText(project.status) }}
				</div>
			</div>
			<v-row :style="{ flex: '0' }">
				<v-col cols="12" lg="8" md="8" sm="12" xs="12" class="pb-0 pl-4">
					<div id="dummy-click" class="d-flex flex-column">
						<v-text-field
							:rules="nameRules"
							:disabled="isViewOnly"
							v-model="project.name"
							clearable
							label="Name"
							class="field-border-radius header-name"
							:hide-details="false"
							outlined 
							v-test="'project-name'"/>
					</div>
				</v-col>
			</v-row>
			<div v-if="tab != 2 && isAuthenticated && !isViewOnly" class="menu d-flex">
				<div class="d-flex flex-column menu-action">
					<v-tooltip :disabled="this?.$vuetify.breakpoint.smAndDown" top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
						<template v-slot:activator="{ on, attrs }">
							<div class="text-center d-flex flex-column justify-center align-center" :disabled="isViewOnly" v-bind="attrs" v-on="on">
								<v-btn
									ref="commentButtonRef"
									@click="startComment"
									fab
									elevation="0"
									v-bind="attrs"
									v-on="on"
									id="more-actions"
									v-test="'project-comments-button'">
									<v-icon>icon-chat-stroke</v-icon>
								</v-btn>
								<div class="text">Comments</div>
							</div>
						</template>
						<span>Add new comments.</span>
					</v-tooltip>
				</div>
				<div class="d-flex flex-column menu-action">
					<v-tooltip top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
						<template v-slot:activator="{ on, attrs }">
							<div class="text-center d-flex flex-column justify-center align-center" :disabled="isViewOnly" v-bind="attrs" v-on="on">
								<ProjectActionFAB
									v-if="project.id"
									:project-id="project.id"
									:shouldShowProjectActionFAB="shouldShowProjectActionFAB" />

								<div class="text custom-padding">Add Content</div>
							</div>
						</template>
						<span>Add new content to this session.</span>
					</v-tooltip>
				</div>
				<div
					:disabled="isViewOnly"
					v-if="!isViewOnly && !!project"
					class="d-flex flex-column menu-action">
					<v-tooltip top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
						<template v-slot:activator="{ on, attrs }">
							<div class="text-center d-flex flex-column justify-center align-center" v-bind="attrs" v-on="on">
								<ShareActionDialog
									:project-name="project.name"
									:project-id="project.id"
									@shared="refreshProject"
									@access-changed="refreshPermissions"
									:access="accessType"
									@copy-link="copyLink">
								</ShareActionDialog>
								<div class="text pl-1">Share</div>
							</div>
						</template>
						<span>Share this project.</span>
					</v-tooltip>
				</div>
				<div class="d-flex flex-column menu-action">
					<v-menu
						v-model="moreOptionsOpened"
						:close-on-content-click="true"
						:close-on-click="true"
						:offset-y="true"
						:min-width="320"
						top
						:z-index="0"
						@input="handleMoreOptionsInput">
						<template v-slot:activator="{ on, attrs }">
							<v-btn fab elevation="0" v-bind="attrs" v-on="on" id="more-actions" v-test="'project-more-actions-button'" >
								<v-icon>icon-dots-horizontal-more</v-icon>
							</v-btn>
							<div class="text">More Actions</div>
						</template>

						<v-list rounded class="more-actions">
							<v-list-item class="copy-customer-link" id="copy-customer-link" @click="copyLink" v-test="'project-more-options-copy-project-link'">
								<v-list-item-icon>
									<v-icon>icon-link-double</v-icon>
								</v-list-item-icon>
								<v-list-item-content> Copy Project Link </v-list-item-content>
							</v-list-item>

							<v-list-item v-if="!isViewOnly" class="delete-project" @click="deleteProject" v-test="'project-more-options-delete-project'">
								<v-list-item-icon>
									<v-icon>icon-trash-full-stroke</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Delete Project</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
			<div>
				<v-tabs
					v-if="isAuthenticated && !isViewOnly"
					v-model="tab"
					align-tabs="center"
					bg-color="transparent"
					:grow="true"
					color="transparent"
					class="custom-tabs">
					<v-tab value="content" @click="removeHighlight">CONTENT</v-tab>
					<v-tab value="details" @click="removeHighlight">DETAILS</v-tab>
				</v-tabs>
			</div>
			<v-row class="project-row flex-column" :class="{ 'overflow-hidden': isCommenting }">
				<v-col v-if="tab == 0">
					<v-card class="page media-card">
						<v-card-text class="pad-equal">
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-videos'">
									<v-icon>icon-video-play-stroke</v-icon>
									<div class="heading">Videos {{ " " }}</div>
									<div class="heading">({{ this.recordings.length }})</div>
								</div>
								<div class="">
									<v-btn
										v-if="recordings.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'recordings')"
										v-test="'project-videos-view-all'"
										>{{ recordings.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<RecordingView
								v-if="recordings.length > 0"
								:is-view-only="isViewOnly"
								:recordings-loading="recordingsLoading"
								:project-id="project.id"
								:file-list="recordings"
								:open-video-light-box="openVideoLightBox"
								:is-mobile-view="true"
								v-test="'project-videos-timeline-tiles'"
							/>
								<v-skeleton-loader
								v-else-if="recordingsLoading"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="recordingsLoading"
								type="image" />
							<span v-else>No recordings have been taken or uploaded yet.</span>
						</v-card-text>
					</v-card>
					<v-card
						flat
						v-show="isLightboxThumbsOpen"
						class="project-card media-card"
						:class="isLightboxThumbsOpen ? '' : 'page'">
						<v-card-title class="pb-0">
							<div class="d-flex media-header mb-0" v-test="'project-photos'">
								<v-icon>icon-snapshot</v-icon>
								<span class="heading">Photos</span>
							</div>
						</v-card-title>
						<div v-if="snapshots.length > 0" class="snapshots" :class="isLightboxThumbsOpen ? 'fixed' : ''" @touchmove="(e) => { e.stopPropagation(); }"">
							<!--Snapshot controls-->
							<v-card-text class="snapshot-controls snapshot-actions">
								<!--Primary controls-->
								<div class="selection">

									<!--Cancel-->
									<v-btn v-if="bulkSelect" elevation="0" @click="() => cancelBulkSelect('snapshots')">
										Back
									</v-btn>

									<!--Select all-->
									<v-btn v-if="bulkSelect && snapshots.length > 1" elevation="0" @click="() => selectAll('snapshots')">
										{{
											selectedSnapshotIds.length == snapshots.length
												? "Deselect All"
												: "Select All"
										}}
									</v-btn>
								</div>

								<!--Secondary controls-->
								<div class="actions">
									<!--Download-->
									<v-btn
										v-if="bulkSelect && selectedSnapshotIds.length > 0"
										fab
										small
										elevation="0"
										@click="() => downloadSelected('snapshots')">
										<v-icon class="mr-0">icon-download</v-icon>
									</v-btn>

									<!--Delete-->
									<v-btn
										v-if="
											!isViewOnly &&
											bulkSelect &&
											selectedSnapshotIds.length > 0
										"
										fab
										small
										elevation="0"
										@click="() => deleteSelected('snapshots')">
										<v-icon class="mr-0" color="red">icon-trash-full-stroke</v-icon>
									</v-btn>
								</div>

								<div v-if="showGalleryCloseButton" class="close-gallery">
									<v-btn elevation="0" icon @click="closeGallery">
										<i class="icon-close-medium" />
									</v-btn>
								</div>
							</v-card-text>

							<!--Snapshots-->
							<v-card-text class="pt-0">
								<v-row style="margin: 0">
									<v-col
										v-for="snapshot in snapshots"
										:key="snapshotKey(snapshot)"
										class="d-flex child-flex"
										cols="6"
										>
										<v-img
											:src="snapshot.src"
											:lazy-src="snapshot.thumbnail"
											aspect-ratio="1"
											class="grey lighten-2"
											@click="openLightBox(snapshot.id)"
										>
											<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular
												indeterminate
												color="grey lighten-5"
												></v-progress-circular>
											</v-row>
											</template>
											<!-- Selection icon at the top-right corner -->
											<div class="selection-icon" @click.stop="toggleSelection(snapshot.id)">
												<div
													class="circle"
													:class="{ selected: isSnapshotSelected(snapshot.id) }">
													<span class="text-h5 white--text centre-number" color="green" v-if="isSnapshotSelected(snapshot.id)">
														{{ getSnapshotSelectionIndex(snapshot.id) }}
													</span>
												</div>
											</div>

											<!-- Overlay to indicate selection -->
											<v-overlay
											absolute
											:opacity="0.72"
											@click.stop="isSnapshotSelected(snapshot.id) ? toggleSelection(snapshot.id) : openLightBox(snapshot.id)"
											:value="isSnapshotSelected(snapshot.id)"
											></v-overlay>
										</v-img>
									</v-col>
								</v-row>
							</v-card-text>
						</div>
						<v-card-text v-else class="no-snapshots">
							{{ loadingSnapshots ? "Loading photos..." : "No photos have been taken or uploaded yet." }}
						</v-card-text>
					</v-card>
					<v-card class="media-card">
						<v-card-text>
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-photos'">
									<v-icon>icon-snapshot</v-icon>
									<div class="heading">Photos</div>
									<div class="heading">({{ this.snapshots.length }})</div>
								</div>

								<div class="">
									<v-btn
										v-if="snapshots.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'snapshots')"
										v-test="'project-photos-view-all'"
										>{{ snapshots.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<v-slide-group v-if="this && snapshots.length > 0" class="d-flex photo-container" ref="slideGroup" v-model="selected" v-test="'project-photos-timeline-tiles'">
								<v-slide-item class="mx-2 snapshot clickable" v-for="snapshot in snapshots" :key="snapshot.id">
									<v-img
										:src="snapshot.src"
										:width="imageWidth"
										:height="imageHeight"
										:max-height="imageHeight"
										:max-width="imageWidth"
										:min-width="imageWidth"
										:lazy-src="snapshot.thumbnail"
										crossorigin="anonymous"
										@click="openLightBox(snapshot.id)">
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular
													indeterminate
													color="grey lighten-5"></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-slide-item>
							</v-slide-group>
							<v-skeleton-loader
								v-else-if="loadingSnapshots"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="loadingSnapshots"
								type="image" />
							<span v-else>No photos have been taken or uploaded yet.</span>
						</v-card-text>
					</v-card>
					<v-card class="media-card">
						<v-card-text>
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-documents'">
									<v-icon>icon-file-blank-solid</v-icon>
									<div class="heading">Documents</div>
									<div class="heading">({{ this.files.length }})</div>
								</div>

								<div class="">
									<v-btn
										v-if="files.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'files')"
										v-test="'project-files-view-all'"
										>{{ files.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<div v-if="bulkSelectFiles && selectedFileIds.length > 0">
								<v-btn fab small elevation="0" @click="() => deleteSelected('files')">
									<v-icon class="mr-0">icon-trash-full-stroke</v-icon>
								</v-btn>
								<v-btn fab small elevation="0" @click="() => downloadSelected('files')">
									<v-icon class="mr-0">icon-download</v-icon>
								</v-btn>
							</div>
							<DocumentView
								v-if="files.length > 0"
								:is-view-only="isViewOnly"
								:project-id="project.id"
								:file-list="files"
								:is-mobile-view="true" />
							<v-skeleton-loader
								v-else-if="loadingFiles"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="loadingFiles"
								type="image" />
							<span v-else>No documents have been uploaded yet.</span>
						</v-card-text>
					</v-card>
				</v-col>
				<!-- <div v-if="snapshots.length > 0" class="snapshots mb-16" :class="isLightboxThumbsOpen ? 'fixed' : ''">
					<v-card-text class="pt-0">
						<v-row style="margin: 0">
							<v-col
								v-for="snapshot in snapshots"
								:key="snapshotKey(snapshot)"
								class="d-flex child-flex"
								cols="6">
								<v-img
									:src="snapshot.src"
									:lazy-src="snapshot.src"
									crossorigin="anonymous"
									aspect-ratio="1"
									class="grey lighten-2"
									@click="openLightBox(snapshot.id)">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular
												indeterminate
												color="grey lighten-5"></v-progress-circular>
										</v-row>
									</template>
									<v-overlay
										:absolute="true"
										:opacity="isSnapshotSelected(snapshot.id) ? 0.32 : 0"
										:value="bulkSelect"
										class="select-overlay"
										:class="isSnapshotSelected(snapshot.id) ? 'selected' : ''"
										@click="toggleSelection(snapshot.id)">
										<i
											:class="
												isSnapshotSelected(snapshot.id)
													? 'icon-circle-check'
													: 'icon-d12-stroke'
											" />
									</v-overlay>
								</v-img>
							</v-col>
						</v-row>
					</v-card-text>
				</div> -->
				<!-- <v-card-text class="no-snapshots">
					{{ loadingSnapshots ? "Loading snapshots..." : "No snapshots have been taken yet." }}
				</v-card-text> -->
				<v-col v-if="tab == 1" cols="12" lg="4" md="4" sm="12" xs="12" class="video-column">
					<div class="project-details">
						<v-select
							:disabled="isViewOnly || hasFreeTrialEnded"
							outlined
							hide-details
							id="project-status"
							label="Status"
							class="status-input"
							v-model="project.status"
							item-text="label"
							item-value="value"
							:items="[
								{ label: 'Not Started', value: 'NotStarted' },
								{ label: 'In Progress', value: 'InProgress' },
								{ label: 'Completed', value: 'Completed' },
							]"
							v-test="'project-status'">
						</v-select>
						<div class="limit-height">
							<vuetify-google-autocomplete
								:disabled="hasFreeTrialEnded || saving || isViewOnly"
								class="minimize-height"
								density="compact"
								outlined
								id="map"
								v-model="address"
								label="Address"
								auto-select-first
								return-object
								no-data-text="No addresses found"
								ref="autocomplete"
								placeholder="Address is optional"
								@placechanged="getAddressData"
								v-test="'project-address'">
								<template #append>
									<v-icon @click="clearAddress" v-if="!isViewOnly && address.length > 0">icon-close-circle-stroke</v-icon>
								</template>
							</vuetify-google-autocomplete>
						</div>
						<CustomerAutocomplete
							ref="customerSearch"
							v-model="project.Customers"
							:disabled="isViewOnly || hasFreeTrialEnded || saving"
							@change="checkDirty"
							@remove="remove"
							@input="checkDirty"
							@create-customer="openCreateCustomerDialog"
							v-test="'project-contact'"
						/>
						<!--Tags-->
						<v-combobox
							outlined
							:hide-details="!project.Labels || project.Labels.length == 0"
							v-model="project.Labels"
							:disabled="hasFreeTrialEnded || saving || isViewOnly"
							item-text="name"
							item-value="id"
							ref="projectsLabelComobobox"
							:items="labels"
							label="Tags"
							multiple
							chips
							deletable-chips
							:maxLength="20"
							return-object
							@input="validateTags"
							v-test="'project-tags'">
							<template v-slot:selection="{ item }">
								<v-chip @click:close="() => removeTags(item)" class="my-custom-chip" close closable close-icon="icon-close-small" :title="item.name">
									{{ item.name ?? item }}
								</v-chip>
							</template>
						</v-combobox>

						<v-text-field :value="users()" outlined readonly disabled hide-details label="Created By" v-test="'project-created-by'" />
					</div>
				</v-col>
				<transition name="slide-up" :class="{ 'overflow-hidden': isCommenting }">
					<v-col v-if="project.id" v-show="isCommenting" cols="12" lg="4" md="4" sm="12" xs="12" class="video-column">
						<div class="comment-controls" :class="{ 'keyboard-visible': keyboardVisible }">
							<v-card class="page desktop-right"> 
								<CommentBox
									v-if="!loading"
									ref="commentBoxRef"
									:parent-id="project.id"
									:project-id="project.id"
									:has-free-trial-ended="hasFreeTrialEnded"
									:light-box-view="true"
									:parent-type="'project'"
									:is-view-only="isViewOnly"
									:users="teamMembers"
									:me="me"
									@close-comment-box="closeCommentBox" />
							</v-card>
						</div>
					</v-col>
				</transition>
			</v-row>

			<div v-if="isAuthenticated && dirty && !isViewOnly" class="add-item-button d-flex justify-space-evenly pt-2 pb-2">
				<v-btn
					large
					rounded
					elevation="0"
				color="#f5f5f5"
					class="border-btn mb-1"
					width="11rem"
					@click="resetAndStay">
					Cancel
				</v-btn>
				<v-btn
					:disabled="!dirty"
					large
					:loading="saving"
					rounded
					elevation="0"
					class="mb-1"
					color="primary"
					width="11rem"
					@click="save"
					v-test="'project-save'">
					Save</v-btn
				>
			</div>
			<v-dialog
			ref="videosDialog"
			fullscreen
			content-class="no-scroll"
			v-model="videoLightBoxOpen"
			persistent
			no-click-animation>
			<VideoLightBox
				v-if="project.id"
				ref="videoLightBox"
				:is-view-only="isViewOnly"
				:parent-id="project.id"
				:project-id="project.id"
				:user="projectUser"
				:open-light-box="openVideoLightBox"
				:recordings="recordings"
				:users="teamMembers"
				:me="me"
				:has-free-trial-ended="hasFreeTrialEnded"
				@close-lightbox="videoLightBoxOpen = false"
				@take-snapshot="createLightBoxSnapshot"
				@share="share"
				@delete-artifacts="deleteArtifacts"
				@delete-recording="checkBulkSelectRecordings"
				@update:isLightboxThumbsOpen="toggleThumbnails"
			/>
			</v-dialog>

			<v-dialog
			ref="snapshotsDialog"
			fullscreen
			content-class="no-scroll"
			v-model="snapshotLightBoxOpen"
			persistent
			no-click-animation>
			<LightBox
			v-if="project.id"
				ref="lightBox"
				:is-view-only="isViewOnly"
				:parent-id="project.id"
				source="project"
				:project-id="project.id"
				:snapshots="snapshots"
				:has-free-trial-ended="hasFreeTrialEnded"
				:open-light-box="openLightBox"
				:users="teamMembers"
				:me="me"
				@download-artifacts="downloadSelected"
				@delete-artifacts="deleteArtifacts"
				@close-lightbox="snapshotLightBoxOpen = false"
				@update:isLightboxThumbsOpen="toggleThumbnails"
				@delete-snapshot="checkBulkSelect" />
			</v-dialog>
			<CreateCustomerDialog ref="CreateCustomerDialogRef"></CreateCustomerDialog>
			<CreateConversationDialog ref="CreateConversationDialogRef"></CreateConversationDialog>
		</div>
		<SignUpPrompt v-if="isUnauthenticated" @signup-clicked="handleSignupClicked" />
	</div>
	<!-- new beginning-->
	<v-card class="page-content project-page" :class="!isAuthenticated ? 'is_unauthenticated' : ''" v-else>
		<div>
			<div ref="stickyHeader" class="d-flex number-container wide sticky-header" :style="hasFreeTrialEnded ? 'top: 52px !important;' : ''">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<span class="date project-date" v-bind="attrs" v-on="on" v-test="'project-created-date'"
							>Created {{ project.createdAt | formatDateOnlyLong }}
							{{ project.createdAt | formatTimeOnly }}</span
						>
					</template>
					<span>
						{{ project.createdAt | formatDate }}
					</span>
				</v-tooltip>
				<span class="text-subtitle-1 font-weight-bold black--text" v-if="isHeaderSticky"> {{ project.name }}</span>
				<v-card-actions v-if="!isUnauthenticated" class="page-actions desktop pt-6">
					<v-btn rounded elevation="0" @click.stop="reset()" v-test="'project-back'">Back</v-btn>
					<v-btn
						rounded
						elevation="0"
						color="primary"
						:loading="saving"
						:disabled="!dirty"
						@click.stop="save()"
						v-test="'project-save'">
						Save
					</v-btn>
				</v-card-actions>
			</div>
			<v-row :style="{ flex: '0' }" class="mt-2">
				<v-col cols="1" lg="8" md="8" sm="1" xs="1" class="pb-0 left-move">
					<v-text-field
						:rules="nameRules"
						:disabled="isViewOnly"
						v-model="project.name"
						clearable
						label="Name"
						class="field-border-radius header-name"
						outlined 
						v-test="'project-name'"/>
				</v-col>
				<v-col cols="1" lg="4" md="4" sm="1" xs="1" class="pb-0 left-move">
					<div v-if="!isViewOnly && !!project" class="menu d-flex no-margin">
						<div class="d-flex flex-column menu-action">
						<v-tooltip z-index="100" :disabled="this?.$vuetify.breakpoint.smAndDown" top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
							<template v-slot:activator="{ on, attrs }">
								<div class="text-center d-flex flex-column justify-center align-center" v-bind="attrs" v-on="on">
									<v-btn :retain-focus-on-click="true" fab elevation="0" class="white-bg d-flex justify-center align-center" @click="startComment" v-test="'project-comments-button'">
										<v-icon>icon-chat-stroke</v-icon>
									</v-btn>
									<div class="text">Comments</div>
								</div>
							</template>
							<span>Add new comments.</span>
						</v-tooltip>
						</div>
						<div :disabled="isViewOnly" v-if="!isViewOnly" class="d-flex flex-column menu-action">
							<v-tooltip z-index="100" top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
								<template v-slot:activator="{ on, attrs }">
									<div class="text-center d-flex flex-column justify-center align-center" v-bind="attrs" v-on="on">
										<ShareActionDialog
											:project-name="project.name"
											:project-id="project.id"
											@shared="refreshProject"
											@access-changed="refreshPermissions"
											:access="accessType"
											@copy-link="copyLink">
										</ShareActionDialog>
										<div class="text">Share</div>
									</div>
								</template>
								<span>Share this project.</span>
							</v-tooltip>
						</div>
						<div :disabled="isViewOnly" class="d-flex flex-column menu-action">
							<v-tooltip z-index="100" top :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false">
								<template v-slot:activator="{ on, attrs }">
									<div class="text-center d-flex flex-column justify-center align-center" :disabled="isViewOnly" v-bind="attrs" v-on="on">
										<ProjectActionFAB
											v-if="project.id"
											:project-id="project.id"
											:shouldShowProjectActionFAB="shouldShowProjectActionFAB" />
										<div class="text">Add Content</div>
									</div>
								</template>
								<span>Add new content to this project.</span>
							</v-tooltip>
						</div>
						<div :disabled="isViewOnly" class="d-flex flex-column menu-action">
							<v-menu
								v-model="moreOptionsOpened"
								:close-on-content-click="true"
								:close-on-click="true"
								:offset-y="true"
								:min-width="320"
								top
								:z-index="100"
								@input="handleMoreOptionsInput">
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="white-bg" fab elevation="0" v-bind="attrs" v-on="on" id="more-actions" v-test="'project-more-actions-button'">
										<v-icon>icon-dots-horizontal-more</v-icon>
									</v-btn>
									<div class="text">More Actions</div>
								</template>

								<v-list rounded class="more-actions">
										<v-list-item
											class="copy-customer-link"
											id="copy-customer-link"
											@click="copyLink"
											v-test="'project-more-options-copy-project-link'">
											<v-list-item-icon>
												<v-icon>icon-link-double</v-icon>
											</v-list-item-icon>
											<v-list-item-content> Copy Project Link </v-list-item-content>
										</v-list-item>
										<v-list-item v-if="!isViewOnly" class="delete-project" @click="deleteProject" v-test="'project-more-options-delete-project'">
											<v-list-item-icon>
												<v-icon>icon-trash-full-stroke</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>Delete Project</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row class="project-row">
				<v-col cols="12" lg="8" md="8" sm="12" xs="12" class="video-column pad-equal" v-show="true">
					<div flat class="page media-card">
						<v-card-text class="pad-equal">
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-videos'">
									<v-icon>icon-video-play-stroke</v-icon>
									<div class="heading">Videos {{ " " }}</div>
									<div class="heading">({{ this.recordings.length }})</div>
								</div>
								<div class="">
									<v-btn
										v-if="recordings.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'recordings')"
										v-test="'project-videos-view-all'"
										>{{ recordings.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<RecordingView
								v-if="recordings.length > 0"
								:is-view-only="isViewOnly"
								:recordings-loading="recordingsLoading"
								:project-id="project.id"
								:file-list="recordings"
								:open-video-light-box="openVideoLightBox"
								:is-mobile-view="true"
								v-test="'project-videos-timeline-tiles'"
							/>
							<v-skeleton-loader
								v-else-if="recordingsLoading"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="recordingsLoading"
								type="image" />
							<span v-else>No recordings have been taken or uploaded yet.</span>
							<div v-if="recordings.length == 0" class="mt-10"></div>
							</v-card-text>
					</div>
					<v-card
						flat
						v-show="isLightboxThumbsOpen"
						class="project-card media-card"
						:class="isLightboxThumbsOpen ? '' : 'page'">
						<v-card-title class="pb-0">
							<div class="d-flex media-header mb-0" v-test="'project-photos'">
								<v-icon>icon-snapshot</v-icon>
								<span class="heading">Photos</span>
							</div>
						</v-card-title>
						<div v-if="snapshots.length > 0" class="snapshots" :class="isLightboxThumbsOpen ? 'fixed' : ''" @touchmove="(e) => { e.stopPropagation(); }"">
							<!--Snapshot controls-->
							<v-card-text class="snapshot-controls snapshot-actions">
								<!--Primary controls-->
								<div class="selection">

									<!--Cancel-->
									<v-btn v-if="bulkSelect" elevation="0" @click="() => cancelBulkSelect('snapshots')">
										Back
									</v-btn>

									<!--Select all-->
									<v-btn v-if="bulkSelect && snapshots.length > 1" elevation="0" @click="() => selectAll('snapshots')">
										{{
											selectedSnapshotIds.length == snapshots.length
												? "Deselect All"
												: "Select All"
										}}
									</v-btn>
								</div>

								<!--Secondary controls-->
								<div class="actions">
									<!--Download-->
									<v-btn
										v-if="bulkSelect && selectedSnapshotIds.length > 0"
										fab
										small
										elevation="0"
										@click="() => downloadSelected('snapshots')">
										<v-icon class="mr-0">icon-download</v-icon>
									</v-btn>

									<!--Delete-->
									<v-btn
										v-if="
											!isViewOnly &&
											bulkSelect &&
											selectedSnapshotIds.length > 0
										"
										fab
										small
										elevation="0"
										@click="() => deleteSelected('snapshots')">
										<v-icon class="mr-0" color="red">icon-trash-full-stroke</v-icon>
									</v-btn>
								</div>

								<div v-if="showGalleryCloseButton" class="close-gallery">
									<v-btn elevation="0" icon @click="closeGallery">
										<i class="icon-close-medium" />
									</v-btn>
								</div>
							</v-card-text>

							<!--Snapshots-->
							<v-card-text class="pt-0">
								<v-row style="margin: 0">
									<v-col
										v-for="snapshot in snapshots"
										:key="snapshotKey(snapshot)"
										class="d-flex child-flex"
										cols="6"
										>
										<v-img
											:src="snapshot.src"
											:lazy-src="snapshot.thumbnail"
											aspect-ratio="1"
											class="grey lighten-2"
											@click="openLightBox(snapshot.id)"
										>
											<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular
												indeterminate
												color="grey lighten-5"
												></v-progress-circular>
											</v-row>
											</template>
											<!-- Selection icon at the top-right corner -->
											<div class="selection-icon" @click.stop="toggleSelection(snapshot.id)">
												<div
													class="circle"
													:class="{ selected: isSnapshotSelected(snapshot.id) }">
													<span class="text-h5 white--text centre-number" color="green" v-if="isSnapshotSelected(snapshot.id)">
														{{ getSnapshotSelectionIndex(snapshot.id) }}
													</span>
												</div>
											</div>

											<!-- Overlay to indicate selection -->
											<v-overlay
											absolute
											:opacity="0.72"
											@click.stop="isSnapshotSelected(snapshot.id) ? toggleSelection(snapshot.id) : openLightBox(snapshot.id)"
											:value="isSnapshotSelected(snapshot.id)"
											></v-overlay>
										</v-img>
									</v-col>
								</v-row>
							</v-card-text>
						</div>
						<v-card-text v-else class="no-snapshots">
							{{ loadingSnapshots ? "Loading photos..." : "No photos have been taken or uploaded yet." }}
						</v-card-text>
					</v-card>

					<div flat class="project-card media-card" :class="isLightboxThumbsOpen ? '' : 'page'">
						<v-card-text class="pad-equal">
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-photos'">
									<v-icon>icon-snapshot</v-icon>
									<div class="heading">Photos</div>
									<div class="heading">({{ this.snapshots.length }})</div>
								</div>

								<div class="">
									<v-btn
										v-if="snapshots.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'snapshots')"
										v-test="'project-photos-view-all'"
										>{{ snapshots.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<v-slide-group
								v-if="this && snapshots.length > 0"
								class="d-flex photo-container"
								show-arrows
								v-test="'project-photos-timeline-tiles'">
								<template v-slot:prev="{ on, attrs }">
											<button v-bind="attrs" v-on="on" v-test="'photos-left-navigation'">
											<v-icon>icon-caret-left</v-icon>
											</button>
										</template>
										<template v-slot:next="{ on, attrs }">
											<button v-bind="attrs" v-on="on" v-test="'photos-right-navigation'">
											<v-icon>icon-caret-right</v-icon>
											</button>
										</template>
								<v-slide-item  class="mx-2 snapshot clickable" v-for="snapshot in snapshots" :key="snapshot.id">
									<v-img
										:src="snapshot.src"
										:width="imageWidth"
										:height="imageHeight"
										:max-height="imageHeight"
										:max-width="imageWidth"
										:min-width="imageWidth"
										:lazy-src="snapshot.thumbnail"
										crossorigin="anonymous"
										@click="openLightBox(snapshot.id)">
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular
													indeterminate
													color="grey lighten-5"></v-progress-circular>
											</v-row>
										</template>
										<v-overlay
											:absolute="true"
											:opacity="isSnapshotSelected(snapshot.id) ? 0.32 : 0"
											:value="bulkSelect"
											class="select-overlay"
											:class="isSnapshotSelected(snapshot.id) ? 'selected' : ''"
											@click="toggleSelection(snapshot.id)">
											<i
												:class="
													isSnapshotSelected(snapshot.id)
														? 'icon-circle-check'
														: 'icon-d12-stroke'
												" />
										</v-overlay>
									</v-img>
								</v-slide-item>
							</v-slide-group>

							<v-skeleton-loader
								v-else-if="loadingSnapshots"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="loadingSnapshots"
								type="image" />
							<span v-else>No photos have been taken or uploaded yet.</span>
							<div v-if="snapshots.length == 0" class="mt-10"></div>
						</v-card-text>
					</div>

					<div flat class="project-card media-card" :class="isLightboxThumbsOpen ? '' : 'page'">
						<v-card-text class="pad-equal">
							<div class="d-flex mt-2 video-title">
								<div class="d-flex page media-header" v-test="'project-documents'">
									<v-icon>icon-file-blank-solid</v-icon>
									<div class="heading">Documents</div>
									<div class="heading">({{ this.files.length }})</div>
								</div>

								<div class="">
									<v-btn
										v-if="files.length > 0"
										class="white--text view-button"
										color="primary"
										elevation="0"
										@click="() => (showArtifactView = 'files')"
										v-test="'project-files-view-all'"
										>{{ files.length == 1 ? "View" : "View All"}}</v-btn
									>
								</div>
							</div>
							<div v-if="bulkSelectFiles && selectedFileIds.length > 0">
								<v-btn fab small elevation="0" @click="() => deleteSelected('files')">
									<v-icon class="mr-0">icon-trash-full-stroke</v-icon>
								</v-btn>
								<v-btn fab small elevation="0" @click="() => downloadSelected('files')">
									<v-icon class="mr-0">icon-download</v-icon>
								</v-btn>
							</div>
							<DocumentView
								v-if="files.length > 0"
								:is-view-only="isViewOnly"
								:project-id="project.id"
								:file-list="files"
								:is-mobile-view="false" />
							<v-skeleton-loader
								v-else-if="loadingFiles"
								class="video-skeleton"
								height="80px"
								weight="80px"
								:loading="loadingFiles"
								type="image" />
							<span v-else>No documents have been uploaded yet.</span>
							<div v-if="files.length == 0" class="mt-10"></div>
						</v-card-text>
					</div>

					<!-- <v-card flat class="page media-card">
					<div>
						<v-card-text>
							<div class="d-flex media-header">
								<v-icon>icon-snapshot</v-icon>
								<span class="heading">Snapshot Gallery</span>
							</div>
							<div class="d-flex snapshot-actions snapshot-controls">
								<div class="d-flex selection">
									<v-btn
										v-if="!bulkSelect && snapshots.length > 0"
										elevation="0"
										@click="bulkSelect = true">
										Bulk Select
									</v-btn>
									<v-btn elevation="0" v-if="bulkSelect" @click="cancelBulkSelect">Cancel</v-btn>
									<v-btn elevation="0" v-if="bulkSelect" @click="() => selectAll('snapshots')">{{
										selectedSnapshotIds.length == snapshots.length ? "Deselect All" : "Select All"
									}}</v-btn>
								</div>
								<div class="d-flex actions" v-if="bulkSelect && selectedSnapshotIds.length > 0">
									<v-btn fab small elevation="0" @click="() => downloadSelected('snapshots')">
										<v-icon>icon-download</v-icon>
									</v-btn>
									<v-btn fab small elevation="0" @click="() => deleteSelected('snapshots')">
										<v-icon color='red'>icon-trash-full-stroke</v-icon>
									</v-btn>
								</div>
							</div>
							<div
								v-if="snapshots.length > 0"
								class="snapshots"
								:class="isLightboxThumbsOpen ? 'fixed' : ''">
								
								<v-card-text>
									<v-row>
										<v-col
											v-for="snapshot in snapshots"
											:key="snapshotKey(snapshot)"
											class="d-flex child-flex"
											cols="6">
											<v-img
												:src="snapshot.src"
												:lazy-src="snapshot.src"
												aspect-ratio="2"
												class="black snapshot-thumbnail"
												@click="openLightBox(snapshot.id)">
												<template v-slot:placeholder>
													<v-row class="fill-height ma-0" align="center" justify="center">
														<v-progress-circular
															indeterminate
															color="grey lighten-5"></v-progress-circular>
													</v-row>
												</template>
												<v-overlay
													:absolute="true"
													:opacity="isSnapshotSelected(snapshot.id) ? 0.32 : 0"
													:value="bulkSelect"
													class="select-overlay"
													:class="isSnapshotSelected(snapshot.id) ? 'selected' : ''"
													@click="toggleSelection(snapshot.id)">
													<i
														:class="
															isSnapshotSelected(snapshot.id)
																? 'icon-circle-check'
																: 'icon-d12-stroke'
														" />
												</v-overlay>
											</v-img>
										</v-col>
									</v-row>
								</v-card-text>
							</div>
							<v-card-text v-else>
								{{ loadingSnapshots ? "Loading snapshots..." : "No snapshots have been taken yet." }}
							</v-card-text>
						</v-card-text>
					</div>
				</v-card> -->
				<div class="page height-control">
						<div class="project-details">
							<div class="comment-box pa-2" v-if="project.id && !loading">
								<CommentBox
									ref="commentBoxRef"
									:parent-id="project.id"
									:project-id="project.id"
									:parent-type="'project'"
									:has-free-trial-ended="hasFreeTrialEnded"
									:is-view-only="isViewOnly"
									:users="teamMembers"
									:me="me"
									@close-comment-box="closeCommentBox" />
							</div>
						</div>
					</div>
				</v-col>
				<v-col v-if="isAuthenticated" cols="12" lg="4" md="4" sm="12" xs="12" class="video-column">
					<v-card-text>
						<div class="page mb-4 pl-4 pr-4">
							<div class="project-details pt-3">
								<div class="d-flex align-center pb-8" v-test="'project-about'">
									<v-icon color="primary">icon-info-circle-stroke</v-icon>
									<div class="pl-2 text-h6 font-weight-bold">About</div>
								</div>
								<v-select
									outlined
									hide-details
									id="project-status"
									label="Status"
									class="status-input"
									v-model="project.status"
									item-text="label"
									item-value="value"
									:disabled="hasFreeTrialEnded || saving || isViewOnly"
									:items="[
										{ label: 'Not Started', value: 'NotStarted' },
										{ label: 'In Progress', value: 'InProgress' },
										{ label: 'Completed', value: 'Completed' },
									]"
									v-test="'project-status'">
								</v-select>

								<vuetify-google-autocomplete
									outlined
									id="map"
									v-model="address"
									label="Address"
										auto-select-first
									return-object
									no-data-text="No addresses found"
									:disabled="hasFreeTrialEnded || saving || isViewOnly"
									ref="autocomplete"
									placeholder="Address is optional"
									@placechanged="getAddressData"
									v-test="'project-address'">
									<template #append>
									<v-icon @click="clearAddress" v-if="!isViewOnly && address.length > 0">icon-close-circle-stroke</v-icon>
								</template>
							</vuetify-google-autocomplete>
							<CustomerAutocomplete
								ref="customerSearch"
								v-model="project.Customers"
								:disabled="isViewOnly || hasFreeTrialEnded || saving"
								@change="checkDirty"
								@remove="remove"
								@input="checkDirty"
								@create-customer="openCreateCustomerDialog"
								v-test="'project-contact'"
							/>

							<!--Tags-->
							<v-combobox
								outlined
								:hide-details="!project.Labels || project.Labels.length == 0"
								v-model="project.Labels"
								:disabled="isViewOnly || hasFreeTrialEnded || saving"
								item-text="name"
								item-value="id"
								ref="projectsLabelComobobox"
								:items="labels"
								label="Tags"
								multiple
								chips
								deletable-chips
								:maxLength="20"
								return-object
								@input="validateTags"
								v-test="'project-tags'">
								<template v-slot:selection="{ item }">
									<v-chip @click:close="() => removeTags(item)" class="my-custom-chip" close closable close-icon="icon-close-small" :title="item.name">
										{{ item.name ?? item }}
									</v-chip>
								</template>
							</v-combobox>

								<v-text-field
									:value="users()"
									outlined
									readonly
									disabled
									hide-details
									label="Created By"
									v-test="'project-created-by'" />
							</div>
						</div>
					</v-card-text>
				</v-col>
				<v-col v-if="tab == 2" cols="12" lg="4" md="4" sm="12" xs="12" class="video-column"> </v-col>
			</v-row>

			<v-dialog
			ref="videosDialog"
			fullscreen
			v-model="videoLightBoxOpen"
			content-class="no-scroll"
			persistent
			no-click-animation>
			<VideoLightBox
				v-if="project.id"
				ref="videoLightBox"
				:is-view-only="isViewOnly"
				:parent-id="project.id"
				:project-id="project.id"
				:open-light-box="openVideoLightBox"
				:has-free-trial-ended="hasFreeTrialEnded"
				:recordings="recordings"
				:user="projectUser"
				:users="teamMembers"
				:me="me"
				@close-lightbox="videoLightBoxOpen = false"
				@take-snapshot="createLightBoxSnapshot"
				@share="share"
				@delete-artifacts="deleteArtifacts"
				@delete-recording="checkBulkSelectRecordings"
				@update:isLightboxThumbsOpen="toggleThumbnails"
			/>
		</v-dialog>

		<v-dialog
			ref="snapshotsDialog"
			fullscreen
			content-class="no-scroll"
			v-model="snapshotLightBoxOpen"
			persistent
			no-click-animation>
			<LightBox
			v-if="project.id"
				ref="lightBox"
				:is-view-only="isViewOnly"
				:parent-id="project.id"
				source="project"
				:project-id="project.id"
				:snapshots="snapshots"
				:has-free-trial-ended="hasFreeTrialEnded"
				:open-light-box="openLightBox"
				:users="teamMembers"
				:me="me"
				@download-artifacts="downloadSelected"
				@delete-artifacts="deleteArtifacts"
				@close-lightbox="snapshotLightBoxOpen = false"
				@update:isLightboxThumbsOpen="toggleThumbnails"
				@delete-snapshot="checkBulkSelect" />
			</v-dialog>

			<CreateCustomerDialog ref="CreateCustomerDialogRef"></CreateCustomerDialog>
			<CreateProjectDialog ref="CreateProjectDialogRef"></CreateProjectDialog>
		</div>
		<SignUpPrompt
			v-if="isUnauthenticated"
			@signup-clicked="handleSignupClicked" />
	</v-card>
</template>

<script>
	import api from "../api";
	import CommentBox from "./CommentBox.vue";
	import CameraShutterClickUrl from "@/assets/camera-shutter-click.mp3";
	import dirty from "../helpers/dirty";
	import Projects from "../helpers/projects";
	import { getCustomerDisplayName } from "../helpers/utilities";
	import "../helpers/emoji";
	import LightBox from "./LightBox.vue";
	import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
	import Cropper from "cropperjs";
	import timezones from "../helpers/timezones";
	import Snapshot from "../helpers/snapshot";
	import VideoPlayer from "./VideoPlayer.vue";
	import moment from "moment-timezone";
	import CreateCustomerDialog from "./CreateCustomerDialog.vue";
	import constants from "../api/constants";
	import ArtifactPage from "./ArtifactPage.vue";
	import DocumentView from "./artifacts/DocumentView.vue";
	import * as Sentry from "@sentry/vue";
	import eventBus from "../helpers/eventBus.js";
	import CreateConversationDialog from "./CreateConversationDialog.vue";
	import VideoLightBox from "./VideoLightBox.vue";
	import ProjectActionFAB from "./ProjectActionFAB.vue";
	import ShareActionDialog from "./ShareActionDialog.vue";
	import { hasFreeTrialEnded } from "@/helpers/hasFreeTrialEnded";
	import uuid from "../helpers/uuid";
	import { AccessType } from "@/enums/AccessType";
	import { ArtifactType } from "@/enums/ArtifactType";
	import { downloadAsZip } from "@/helpers/download";
	import SignUpPrompt from "./SignUpPage.vue";
	import RecordingView from "./artifacts/RecordingView.vue";
	import { calculateTimeout } from "../helpers/utilities";
	import ClipboardWarning from "./ClipboardWarning.vue";
	import { downloadFile } from "../helpers/download";
	import { blurActiveElement } from "../helpers/utilities";
	import CustomerAutocomplete from "./CustomerAutoComplete.vue";
	import { deleteArtifacts, getArtifactTypeConfig } from "../helpers/artifacts";
	import StorageWrapper from "@/helpers/storage";

	export default {
		name: "OrganizationPage",
		components: {
			DocumentView,
			LightBox,
			VideoPlayer,
			CreateCustomerDialog,
			ShareActionDialog,
			ProjectActionFAB,
			ArtifactPage,
			CreateConversationDialog,
			SignUpPrompt,
			CommentBox,
			RecordingView,
			VideoLightBox,
			CustomerAutocomplete,
		},
		props: {
			projectId: {
				type: String,
				required: false,
			},
			shareId: {
				type: String,
				required: false,
			},
		},
		data() {
			return {
				searchCustomer: "",
				isCommenting: false,
				nameRules: [(v) => this.validateProjectName(v) || "Name must be between 1 and 255 characters"],
				fileToUpload: undefined,
				recordingConversations: [],
				bulkSelect: false,
				bulkSelectFiles: false,
				supportedFileTypes: [
					"image/png",
					"image/jpg",
					"image/jpeg",
					"video/mp4",
					"application/pdf",
					"application/msword",
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				],
				selected: 0,
				bypassUnsavedChangesCheck: false,
				canvasData: Cropper.SetCanvasDataOptions,
				project: {},
				customerSelectItems: [],
				projectSelectItems: [],
				conversations: [],
				customers: [],
				keyboardVisible: false,
				conversationsWithMeetingIds: [],
				cropBoxData: Cropper.SetCropBoxDataOptions,
				cropper: {},
				creatingNewCustomer: false,
				cropping: false,
				dirty: false,
				tab: 0,
				labelDirty: false,
				image: {},
				editNotes: false,
				canSaveNotes: false,
				savingNotes: false,
				snapshotNotes: "",
				isLightboxThumbsOpen: false,
				lightBoxIndex: null,
				loading: true,
				startingLoading: true,
				loadingSnapshots: false,
				loadingFiles: false,
				page: parseInt(this.$route.query.page || "1"),
				recordingPage: 1,
				recordingsLoading: false,
				recordings: [],
				recordingCount: 0,
				showArtifactView: "",
				recordingToShare: "",
				currentRecording: 1,
				saving: false,
				savingCrop: false,
				selectedSnapshotIds: [],
				selectedFileIds: [],
				selectedRecordings: [],
				scrollY: parseInt(this.$route.query.scrollY || "0"),
				shareUrl: "",
				sharingId: null,
				showIncompleteBanner: false,
				smCols: 12,
				snapshots: [],
				files: [],
				snapshotCount: 0,
				labels: [],
				searchTimeout: null,
				newSelectedCustomer: null,
				newSelectedProject: null,
				teamMembers: [],
				selectedTeamMember: null,
				moreOptionsOpened: false,
				me: null,
				isTouch: false,
				annotating: false,
				savingAnnotation: false,
				backgroundImage: "",
				conversationToUpload: null,
				openUpload: false,
				backgroundImageCaption: {
					title: "",
					description: "",
				},
				address: "",
				recordingId: null,
				recordingConversationId: null,
				shouldShowProjectActionFAB: true,
				hasFreeTrialEnded: false,
				isViewOnly: true,
				isAuthenticated: false,
				isHeaderSticky: false,
				projectUser: null,
				videoLightBoxOpen: false,
				snapshotLightBoxOpen: false,
			};
		},
		async beforeRouteLeave(to, from, next) {
			if (this.bypassUnsavedChangesCheck || !this.dirty) {
				next();
				return;
			}
			if (
				await this.$root.$confirm.open(
					"You have unsaved changes",
					"Are you sure you want to navigate away from this page and lose your changes?",
					{},
					false,
					"Discard changes",
					"Keep editing",
					true,
					true,
				)
			) {
				next();
			}
		},
		methods: {
			lightboxDialogInput(e) {
				console.log(e);
			},
			closeCommentBox() {
				this.isCommenting = false;
			},
			getSnapshotSelectionIndex(snapshotId) {
				return this.selectedSnapshotIds.indexOf(snapshotId) + 1;
			},
			removeTags(item) {
				if (item.id) {
					this.project.Labels = this.project.Labels.filter((l) => l.id !== item.id);
				}
				else {
					this.project.Labels = this.project.Labels.filter((l) => l !== item);
				}
			},
			handleSignupClicked(isSignUpScreen) {
				if (isSignUpScreen) {
					document.body.style.overflow = "hidden";
				} else {
					document.body.style.overflow = "auto";
				}
			},
			clearAddress() {
				this.address = "";
				this.project.addressId = null;
				this.project.GeoAddress = null;
				this.checkDirty();
			},
			toggleBulkSelectSnapshots() {
				this.bulkSelect = !this.bulkSelect;
				if (this.snapshots.length == 1 && this.bulkSelect) {
					this.selectedSnapshotIds = [this.snapshots[0].id];
				}
			},
			handleFocus() {
				this.keyboardVisible = true;
			},
			handleBlur() {
				this.keyboardVisible = false;
			},
			handleScroll() {
				const header = this.$refs.stickyHeader;
				const offsetTop = header?.getBoundingClientRect().top ?? 0;
				this.isHeaderSticky = offsetTop <= (this.hasFreeTrialEnded ? 60 : 0);
			},
			startComment() {
				this.isCommenting = !this.isCommenting;
				this.$refs.commentBoxRef?.$refs.addCommentRef?.$refs.commentField?.$refs.input.focus();
				if (this.isMobile) {
					blurActiveElement();
				}
			},
			closeCopyLinkDialog() {
				this.linkCopied = false;
			},
			toggleThumbnails(val) {
				this.isLightboxThumbsOpen = !!val;
			},
			getCustomerDisplayName(record) {
				return getCustomerDisplayName(record);
			},
			async share(recordingId) {
				// Just disable while we create the token.
				this.recordingToShare = recordingId;
				const recording = this.recordings.find((x) => x.id == recordingId);
				const conversationId = recording.conversationId;
				const recordingConversation = this.recordingConversations.find((x) => x.id == conversationId);
				const result = await api.getRecordingShareUrl({
					recordingId: recordingId,
					meetingId: recordingConversation.meetingId,
					conversationId: conversationId,
				});

				const shortUrl = result.url;

				this.recordingToShare = "";

				if (navigator.clipboard) {
					try {
						await navigator.clipboard.writeText(shortUrl);
						const message = "The video link has been copied to the clipboard.";
						this.$toast.success(message, {timeout: calculateTimeout(message)});
					} catch(err) {
						this.$toast.info(
							{
								component: ClipboardWarning,
								props: {
									url: shortUrl,
								},
							},
							{
								timeout: 10000,
								draggable: false,
							}
						);
						console.error("Failed to copy the video link to the clipboard.", err);
					}
				}
			},
			validateProjectName(name) {
				name = name?.trim();
				return name && name.length > 0 && name.length <= 255;
			},
			async refreshProject() {
				this.project = await api.getProject(this.project.id);
				this.sourceProject = JSON.parse(JSON.stringify(this.project));
			},
			async refreshPermissions(item, accessType) {
				if (item.userIdentifier === this.me.user.email || item.userIdentifier === this.me.account.id) {
					if (accessType === constants.REMOVE_ACCESS.value) {
						// redirect to projects page
						this.$router.push("/projects");
					} else {
						this.$router.go(0);
					}
				}
			},
			removeHighlight(event) {
				// Wait for the next tick to ensure the click has been processed
				this.$nextTick(() => {
					event.target.blur();
					this.$forceUpdate();
				});
			},
			async resetAndStay() {
				if (!this.dirty) {
					return;
				}
				if (
					await this.$root.$confirm.open(
						"You have unsaved changes",
						"Are you sure you want to lose your changes?",
						{},
						false,
						"Discard changes",
						"Keep editing",
						true,
						true
					)
				) {
					this.project = JSON.parse(JSON.stringify(this.sourceProject));
					this.newSelectedCustomer = this.project.workflowCustomerId
						? this.project.WorkflowCustomer
						: this.project.Customers[0];
					this.newSelectedProject = this.project;
					await this.loadLabelOptions();
				}
			},
			async reset() {
				if (!this.dirty) {
					this.$router.push("/projects");
					return;
				}
				if (
					await this.$root.$confirm.open(
						"You have unsaved changes",
						"Are you sure you want to navigate away from this page and lose your changes?",
						{},
						false,
						"Discard changes",
						"Keep editing",
						true,
						true,
					)
				) {
					this.project = JSON.parse(JSON.stringify(this.sourceProject));
					this.newSelectedCustomer = this.project.workflowCustomerId
						? this.project.WorkflowCustomer
						: this.project.Customers[0];
					this.newSelectedProject = this.project;
					await this.loadLabelOptions();
					this.$router.push("/projects");
				}
			},
			beautifyText(text) {
				if (!text) {
					return "";
				}
				// convert from camelcase to words
				return text.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
					return str.toUpperCase();
				});
			},
			loadTeamMembers() {
				api.getProjectShares({
					pageSize: 1000, // how many shares can we have per project?
					where: { projectId: this.$route.params.id },
				}).then((result) => {
					const teamMembers = result.rows.filter((x) => x.accessType !== AccessType.Viewer && x.User).map((x) => x.User);
					this.teamMembers = []
					for (const member of teamMembers) {
						if (this.teamMembers.find((x) => x.id === member.id)) {
							continue;
						}
						this.teamMembers.push(member);
					}
				});
			},
			toggleMoreOptions() {
				this.moreOptionsOpened = !this.moreOptionsOpened;
			},
			handleMoreOptionsInput() {
				this.moreOptionsOpened = false;
			},
			getAddressData(addressData, placeResultData) {
				this.project.GeoAddress = {
					latitude: addressData.latitude,
					longitude: addressData.longitude,
					placeId: placeResultData.place_id,
					formattedAddress: placeResultData.formatted_address,
				};
				this.checkDirty();
			},
			done() {
				try {
					if (!this.$route.query.from) {
						this.$router.push("/");
					} else {
						this.$router.push("/projects");
					}
				} catch (err) {
					console.error('Navigation failed:', err);
					// Fallback to home route if navigation fails
					this.$router.push("/");
				}
			},
			stopVideo() {
				const video = document.getElementById(`video-${this.lastRecordingId}`);
				if (video) {
					video.pause();
				}
			},
			nextRecording() {
				if (this.recordings[this.recordingPage - 1]) {
					this.recordingId = this.recordings[this.recordingPage - 1].id;
					this.recordingConversationId = this.recordings[this.recordingPage - 1].conversationId;
				}
			},
			prevRecording() {
				if (this.recordings[this.recordingPage - 1]) {
					this.recordingId = this.recordings[this.recordingPage - 1].id;
					this.recordingConversationId = this.recordings[this.recordingPage - 1].conversationId;
				}
			},
			recordingPageChanged(page) {
				this.lastRecordingId = this.recordingId;
				this.stopVideo();
				if (this.recordings[page - 1]) {
					this.recordingId = this.recordings[page - 1].id;
					this.recordingConversationId = this.recordings[page - 1].conversationId;
				}
				const recordingIndex = this.recordings.findIndex((x) => x.id == this.recordingId);
				this.showIncompleteBanner = this.shouldShowIncompleteBanner(this.recordings[recordingIndex]);
			},
			recordingCarouselChanged(recordingId) {
				this.stopVideo();
				const recordingIndex = this.recordings.findIndex((x) => x.id == recordingId);
				if (recordingIndex != -1) {
					this.recordingPage = recordingIndex + 1;
				}
				this.lastRecordingId = recordingId;
				this.showIncompleteBanner = this.shouldShowIncompleteBanner(this.recordings[recordingIndex]);
			},
			customersList() {
				return this.project?.Customers?.map((x) => x.firstname + " " + x.lastname || x.phone).join(",");
			},
			customerId() {
				return this.project?.Customers.map((x) => x.id).find((x) => x !== undefined);
			},
			async copyLink() {
				if (navigator.clipboard) {
					try {
						this.shareUrl = await this.getShareUrlOfProject();
						await navigator.clipboard.writeText(this.shareUrl);
						const message = "The project link has been copied to the clipboard."
						this.$toast.success(message, {timeout: calculateTimeout(message)});
					} catch(err) {
						const message = "Failed to copy the project link to the clipboard."
						this.$toast.error(message, {timeout: calculateTimeout(message)});
					}
				}
			},
			getUserName(user) {
				if (user.firstname && user.lastname) {
					return user.firstname + " " + user.lastname;
				} else if (user.firstname && !user.lastname) {
					return user.firstname;
				} else if (!user.firstname && user.lastname) {
					return user.lastname;
				} else {
					return user.email;
				}
			},
			openFileViewer(id) {
				if (!this.bulkSelectFiles) {
					this.bulkSelectFiles = true;
					this.selectedFileIds = [id];
				} else {
					if (this.selectedFileIds.includes(id)) {
						this.selectedFileIds = this.selectedFileIds.filter((x) => x !== id);
					} else {
						this.selectedFileIds.push(id);
					}
				}
			},
			users() {
				if (!this.project?.User) {
					return [];
				}
				return [this.getUserName(this.project?.User)];
			},
			getStatusClass() {
				if (constants.areEqual(constants.PROJECT_STATUS.NotStarted, this.project.status)) {
					return "not-started";
				} else if (constants.areEqual(constants.PROJECT_STATUS.InProgress, this.project.status)) {
					return "in-progress";
				} else if (constants.areEqual(constants.PROJECT_STATUS.Completed, this.project.status)) {
					return "completed";
				}
			},
			isTitleUpdated() {
				if (this.project && this.sourceProject) {
					return this.project.name !== this.sourceProject.name;
				}
			},
			checkAddressDirty() {
				if (this.project && this.sourceProject) {
					const sourceAddress = this.sourceProject.GeoAddress;
					const projectAddress = this.project.GeoAddress;
					return sourceAddress?.placeId !== projectAddress?.placeId;
				}
			},
			checkDirty() {
				let isDirty = !!this.fileToUpload && this.areFieldsValidLength() && this.areLabelsValid();
				if (this.project && this.sourceProject) {
					isDirty = isDirty || this.checkArrayDirty(this.project.Labels, this.sourceProject.Labels);
					isDirty = isDirty || this.checkArrayDirty(this.project.Customers, this.sourceProject.Customers);
					isDirty = isDirty || dirty.compare(this.project, this.sourceProject);
					isDirty = isDirty || this.checkAddressDirty();
				}
				this.dirty = isDirty && this.validateProjectName(this.project.name);
			},
			checkArrayDirty(projectArr, sourceProjectArr) {
				const projectLabelsMissingFromSourceProject = projectArr.filter((newLabel) => {
					return !sourceProjectArr.find((existingLabel) => newLabel.id === existingLabel.id);
				});

				const sourceProjectLabelsMissingFromProject = sourceProjectArr.filter((existingLabel) => {
					return !projectArr.find((newLabel) => existingLabel.id === newLabel.id);
				});
				if (
					projectLabelsMissingFromSourceProject.length == 0 &&
					sourceProjectLabelsMissingFromProject.length == 0
				) {
					return false;
				} else {
					return true;
				}
			},
			areLabelsValid() {
				return this.$refs?.projectsLabelComobobox?.validate() ?? true;
			},
			areFieldsValidLength() {
				return this.project.name?.length.isBetween(1, 250);
			},
			editCustomer(customerId) {
				if (!customerId) {
					return;
				}
				this.$router.push("/contacts/" + customerId);
			},
			async openCreateCustomerDialog(searchCustomer = '') {
				if (this.hasFreeTrialEnded) {
					this.$root.$freeTrialEndedDialog.open(this.me.organization);
				} else {
					this.$refs.customerSearch?.closeMenu();
					if (this.project && this.project.id) {
						try {
							const record = await this.$refs.CreateCustomerDialogRef.open(searchCustomer);
							if (record) {
								this.project.Customers.push(record);
								this.$refs.customerSearch.menuIsActive = true;
								this.checkDirty();
							}
						} catch (err) {
							console.error(err);
							Sentry.captureException(err, {
								tags: {
									method: "createNewCustomer",
									file: "ProjectPage",
								},
								extra: {
									projectId: this.project.id,
								},
							});
						}
					}
				}
			},
			async deleteRecording(projectId, recordingId) {
				if (await this.$root.$confirm.deleteOpen("Delete Video", "This will delete the video.")) {
					await api.deleteRecording(projectId, recordingId);
					await this.loadRecordings();
				}
			},
			async deleteProject() {
				if (
					await this.$root.$confirm.deleteOpen("Delete Project", `This will delete the project.`
					)
				) {
					await api.deleteProject(this.project.id);
					this.bypassUnsavedChangesCheck = true;
					this.done();
				}
			},
			async save() {
				try {
					this.saving = true;

					if (this.fileToUpload) {
						const file = await api.uploadFiles(this.conversationToUpload.id, this.fileToUpload, null, {
							projectId: `${this.project.id}`,
							description: `${this.project.name}`,
						});

						if (this.fileToUpload.type.indexOf("image") != -1) {
							this.snapshots.push({
								id: file.id,
								thumbnail: file.uploadResult.signedUrlThumbnail,
								src: file.uploadResult.signedUrl,
								User: this.getUserForSnapshot(file),
								description: file.notes ?? "",
								title: timezones.formatDate(file.createdAt),
								createdAt: file.createdAt,
								conversationId: file.conversationId,
							});
						} else if (this.fileToUpload.type.indexOf("video") != -1) {
							await this.loadRecordings();
						} else {
							const keySplits = file.uploadResult.Key.split(".");
							this.files.push({
								id: file.id,
								src: file.uploadResult.signedUrl,
								description: file.notes ?? "",
								title: file.uploadResult.metadata?.filename ?? timezones.formatDate(file.createdAt),
								extension: keySplits[keySplits.length - 1],
								createdAt: file.createdAt,
							});
						}

						this.fileToUpload = null;
					}

					this.project.Labels = this.project.Labels.map((val) => {
						if (typeof val === "string" || val instanceof String) {
							return { name: val };
						}
						return val;
					});

					if (this.newSelectedCustomer) {
						this.project.newSelectedCustomer = this.newSelectedCustomer;
					}

					if (this.newSelectedProject && this.newSelectedProject.id) {
						this.project.projectId = this.newSelectedProject.id;
					}

					if (this.selectedTeamMember) {
						this.project.selectedTeamMember = this.selectedTeamMember.id;
					}
					// if (this.address) {
					// 	this.project.placeId = this.address.placeId;
					// }
					//how to handle when earlier address is there, now removed
					let request = { ...this.project };
					this.project = await api.updateProject(this.$route.params.id, request, this.address);
					this.sourceProject = JSON.parse(JSON.stringify(this.project));
					this.newSelectedCustomer = this.project.workflowCustomerId
						? this.project.WorkflowCustomer
						: this.project.Customers[0];
					this.newSelectedProject = this.project;
					await this.loadLabelOptions();

					// TODO: Upload files
				} finally {
					this.saving = false;
				}
			},
			async loadSnapshots() {
				this.loadingSnapshots = true;
				this.snapshots = [];
				try {
					const snapshots = await api.getSnapshotsForProject(this.project.id);
					for (const snapshot of snapshots) {
						this.snapshots.push({
							id: snapshot.id,
							thumbnail: snapshot.uploadResult.signedUrlThumbnail,
							src: snapshot.uploadResult.signedUrl,
							User: this.getUserForSnapshot(snapshot),
							description: snapshot.notes ?? "",
							title: timezones.formatDate(snapshot.createdAt),
							createdAt: snapshot.createdAt,
							conversationId: snapshot.conversationId,
						});
					}
					this.$nextTick(() => {
						if (this.$route.query.artifactId && this.$route.query.artifactType) {
							const artifactType = this.$route.query.artifactType;
							const artifactId = this.$route.query.artifactId;
							const isPresent = this.snapshots.find(s => s.id === artifactId)
							if (artifactType == 'photo' && isPresent) {
								this.openLightBox(this.$route.query.artifactId)
							}
						}
					})
				} catch (error) {
					console.error(error);
				}
				this.loadingSnapshots = false;
			},
			shouldShowIncompleteBanner(recording) {
				if (!recording) {
					return true;
				}
				return !recording.isUploadComplete && recording.recordingStatus === "READY";
			},
			loadRecordings() {
				this.recordingsLoading = true;
				api.getArtifactsForProjectInsecure(this.project.id, ArtifactType.Recordings)
					.then((resp) => {
						const result = resp.artifacts;
						this.recordingConversations = resp.conversations;
						this.recordings = result?.sort((a, b) => (a.recordingStart > b.recordingStart ? 1 : -1));
						this.$nextTick(() => {
							if (this.$route.query.artifactId && this.$route.query.artifactType) {
								const artifactType = this.$route.query.artifactType;
								const artifactId = this.$route.query.artifactId;
								const isPresent = this.recordings.find(r => r.id === artifactId)
								if (artifactType == 'video' && isPresent) {
									this.openVideoLightBox(this.$route.query.artifactId)
								}
							}
						})
						this.recordingsLoading = false;
						this.recordingCount = this.recordings.length;
						this.showIncompleteBanner = this.shouldShowIncompleteBanner(this.recordings[0]);
					})
					.catch(() => {
						this.recordingsLoading = false;
					});
			},
			async loadFiles() {
				this.loadingFiles = true;
				this.files = [];
				try {
					const files = await api.getFilesForProject(this.project.id);
					for (const file of files) {
						const keySplits = file.uploadResult.Key.split(".");
						this.files.push({
							id: file.id,
							src: file.uploadResult.signedUrl,
							description: file.notes ?? "",
							title: file.uploadResult.metadata?.filename ?? timezones.formatDate(file.createdAt),
							extension: keySplits[keySplits.length - 1],
							createdAt: file.createdAt,
						});
					}
				} catch (error) {
					console.error(error);
				}
				this.loadingFiles = false;
			},
			getTypeIcon(type) {
				if (!type) return "";
				if (type === "sync") return "icon-video";
				else if (type === "async") return "icon-record-circle";
				return "icon-globe-website-alt";
			},
			getTypeTitle(type) {
				if (!type) return "";
				if (type === "sync") return "Video Call";
				else if (type === "async") return "Recording";
				return "Inbound Video Call";
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			cancelBulkSelect(artifactType) {
				if (artifactType === ArtifactType.Snapshots) {
					this.selectedSnapshotIds = [];
					this.bulkSelect = false;
				} else {
					this.selectedFileIds = [];
					this.bulkSelectFiles = false;
				}
			},
			selectAll(artifactType) {
				if (artifactType === ArtifactType.Snapshots) {
					if (this.selectedSnapshotIds.length == this.snapshots.length) {
						this.selectedSnapshotIds = [];
					} else {
						this.selectedSnapshotIds = [];
						this.selectedSnapshotIds.push(...this.snapshots.map((s) => s.id));
					}
				} else {
					if (this.selectedFileIds.length == this.files.length) {
						this.selectedFileIds = [];
					} else {
						this.selectedFileIds = [];
						this.selectedFileIds.push(...this.files.map((s) => s.id));
					}
				}
			},
			getUserForSnapshot(snapshot) {
				const conversation = this.conversations.find((c) => c.id === snapshot.conversationId);
				if (conversation?.Users?.length > 0) {
					return conversation?.Users[0];
				}
				return null;
			},
			async deleteFile(id) {
				this.selectedFileIds = this.selectedFileIds.filter((x) => x !== id);
				this.files = this.files.filter((x) => x.id !== id);
			},
			async deleteSelected(artifactType, onDelete) {
				// Get the config for proper naming in dialog
				const config = getArtifactTypeConfig(artifactType);

				// Get the relevant arrays based on artifact type
				const selectedIds =
					artifactType === ArtifactType.Snapshots
						? this.selectedSnapshotIds
						: artifactType === ArtifactType.Files
						? this.selectedFileIds
						: this.selectedRecordings;

				const artifacts =
					artifactType === ArtifactType.Snapshots
						? this.snapshots
						: artifactType === ArtifactType.Files
						? this.files
						: this.recordings.map((r) => ({ ...r, conversationId: r.conversationId }));

				const isPlural = selectedIds.length > 1;
				const itemName = isPlural ? config.pluralName : config.singularName;
				const confirmed = await this.$root.$confirm.deleteOpen(
					`Delete ${isPlural ? itemName : config.singularName}`,
					`This will delete the ${selectedIds.length} selected ${itemName}.`
				);

				if (!confirmed) {
					onDelete?.(false);
					return;
				}

				const updatedArtifacts = await deleteArtifacts(artifactType, selectedIds, artifacts);
				onDelete?.(true);

				if (artifactType === ArtifactType.Snapshots) {
					this.snapshots = updatedArtifacts;
					this.selectedSnapshotIds = [];
				} else if (artifactType === ArtifactType.Files) {
					this.files = updatedArtifacts;
					this.selectedFileIds = [];
				} else if (artifactType === ArtifactType.Recordings) {
					this.recordings = updatedArtifacts;
					this.selectedRecordings = [];
				}
			},
			async downloadSelected(artifactType, selectedIds = null) {
				try {
					if (artifactType === ArtifactType.Snapshots) {
						selectedIds = selectedIds ?? this.selectedSnapshotIds;
						const imageUrls = this.snapshots
							.filter((snapshot) => selectedIds.includes(snapshot.id))
							.map((s) => s.src);
						if (imageUrls.length == 1) {
							return await downloadFile(imageUrls[0], null);
						}
						await downloadAsZip(imageUrls, this.project.name, "files", []);
					} else {
						selectedIds = selectedIds ?? this.selectedFileIds;
						const fileUrls = this.files
							.filter((file) => selectedIds.includes(file.id))
							.map((f) => f.src);
						const fileTitles = this.files
							.filter((file) => this.selectedFileIds.includes(file.id))
							.map((f) => f.title);
						if (fileUrls.length == 1) {
							return await downloadFile(fileUrls[0], fileTitles[0]);
						}
						await downloadAsZip(fileUrls, this.project.name, "files", fileTitles);
					}
				} catch(err) {
					//
				}
			},
			async saveNotes(snapshotNotes) {
				// TODO: This code exists 3 times, DEDUPE!
				const snapshot = this.snapshots[this.lightBoxIndex];

				let request = { notes: snapshotNotes };
				await api.updateSnapshot(snapshot.id, request);

				snapshot.description = snapshotNotes;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
			},
			async checkBulkSelect(id) {
				if (this.selectedSnapshotIds.length > 0) {
					const index = this.selectedSnapshotIds.findIndex((x) => x === id);
					if (index !== -1) {
						this.selectedSnapshotIds.splice(index, 1);
					}
				}
			},
			async checkBulkSelectRecordings(id) {
				if (this.selectedRecordings.length > 0) {
					const index = this.selectedRecordings.findIndex((x) => x === id);
					if (index !== -1) {
						this.selectedRecordings.splice(index, 1);
					}
				}
				this.recordings = this.recordings.filter((x) => x.id !== id);
			},
			async deleteSnapshot(indexToDelete) {
				const index = indexToDelete ?? this.lightBoxIndex;
				if (await this.$root.$confirm.deleteOpen("Delete Photo", `This will delete the photo.`)) {
					await api.deleteSnapshot(this.snapshots[index].id);
					this.snapshots.splice(index, 1);
					if (this.snapshots.length > 0) {
						this.lightBoxIndex = null;
						// this lets the lightbox refresh its items properly.
						// if we go from index 0 to 0 the image stays the same even though we just deleted it
						this.$nextTick(() => {
							this.lightBoxIndex = index === 0 ? 0 : Math.max(0, index - 1);
							if (this?.$refs?.lightbox) {
								this.$refs.lightbox.isZooming = false;
							}
						});
					} else {
						if (this?.$refs?.lightbox) {
							this.$refs.lightbox.close();
						}
					}
				}
			},
			isSnapshotSelected(id) {
				return this.selectedSnapshotIds.findIndex((x) => x == id) !== -1;
			},
			isFileSelected(id) {
				return this.selectedFileIds.findIndex((x) => x == id) !== -1;
			},
			toggleSelection(id) {
				const index = this.selectedSnapshotIds.findIndex((x) => x === id);
				if (index == -1) {
					this.selectedSnapshotIds.push(id);
				} else {
					this.selectedSnapshotIds.splice(index, 1);
				}
			},
			toggleFileSelection(id) {
				const index = this.selectedFileIds.findIndex((x) => x === id);
				if (index == -1) {
					this.selectedFileIds.push(id);
				} else {
					this.selectedFileIds.splice(index, 1);
				}
			},
			async loadLabelOptions() {
				const labels = await api.getLabels({ pageSize: 1000, order: [["name", "ASC"]] });
				if (labels) {
					this.labels = labels.rows;
				}
			},
			async openLightBox(id) {
				this.snapshotLightBoxOpen = true;
				this.$nextTick(() => {
					this.$refs.lightBox?.setCurrentSnapshotId(id);
				});
			},
			async openVideoLightBox(id) {
				if (this.bulkSelect) return;
				this.isCommenting = false;
				this.videoLightBoxOpen = true;
				this.$nextTick(() => {
					this.$refs.videoLightBox?.setCurrentRecordingId(id);
				});
			},
			snapshotKey(snapshot) {
				return `snapshot-${snapshot.id}`;
			},
			async getShareUrlOfProject() {
				if (!this.project.shareId) {
					// create a uuid for sharing
					const shareId = uuid.uuidv4();
					this.project = await api.updateProject(this.project.id, {
						shareId,
						genericAccessType: AccessType.Viewer,
					});
				}
				return this.project.shareUrl;
			},
			countCharacters(value) {
				return value?.unicodeLength() || 0;
			},
			countIndexes(value) {
				return value?.unicodelength() < 3 || "Max 3 labels are allowed";
			},
			async deleteArtifacts(artifactType, bulkSelectedIds, onDelete) {
				if (artifactType == ArtifactType.Snapshots) {
					this.selectedSnapshotIds = bulkSelectedIds;
				} else if (artifactType == ArtifactType.Files) {
					this.selectedFileIds = bulkSelectedIds;
				} else if (artifactType == ArtifactType.Recordings) {
					this.selectedRecordings = bulkSelectedIds;
				}
				this.deleteSelected(artifactType, onDelete);
			},
			async createVideoSnapshot(video, recordingId) {
				try {
					const blob = await Snapshot.takeSnapshot(video, video.videoWidth, video.videoHeight, false);
					if (!this.recordingConversationId) {
						const currentRecording = this.recordings.find((r) => r.id === recordingId);
						this.recordingConversationId = currentRecording?.conversationId;
					}
					const snapshot = await api.uploadSnapshot(this.recordingConversationId, blob, null, {
						projectId: `${this.project.id}`,
						description: `${this.project.name.replaceAll(/[^a-zA-Z0-9 -]/g, "")}`, // TODO: Find a better way to sanitize this
						videoTime: `${video.currentTime}`,
						recordingId: recordingId,
					});
					const newSnapshot = {
						id: snapshot.id,
						src: snapshot.uploadResult.signedUrl,
						description: snapshot.notes ?? "",
						User: this.getUserForSnapshot(snapshot),
						title: timezones.formatDate(snapshot.createdAt),
						createdAt: snapshot.createdAt,
						conversationId: snapshot.conversationId,
					};
					// keep at start of array
					this.snapshots.splice(0, 0, newSnapshot);
				} catch (err) {
					console.error(err);
					await this.$root.$confirm.open(
						"Error",
						"It looks like something went wrong and your snapshot failed to upload.  Please try again. If you continue to experience this error, contact our support team at " +
							window.env.VITE_SUPPORT_EMAIL,
						{},
						false,
						"OK",
						null,
						true,
						true,
						true
					);
				}
			},
			async createLightBoxSnapshot(video, recordingId) {
				const audio = new Audio(CameraShutterClickUrl);
				audio.play();
				await this.createVideoSnapshot(video, recordingId);
			},
			async createSnapshot(recordingId) {
				const audio = new Audio(CameraShutterClickUrl);
				audio.play();
				const video = document.getElementById(`video-${recordingId}`);
				await this.createVideoSnapshot(video, recordingId);
			},
			closeVideoGallery() {
				this.$refs.videoLightBox.hideThumbnailTray();
			},
			closeGallery() {
				this.$refs.lightBox.hideThumbnailTray();
				this.cancelBulkSelect('snapshots');
			},
			formatRecordingDate(recording) {
				return moment(recording.recordingStart).format("M/D/YYYY");
			},
			async loadSnapshotsInsecure(snapshots) {
				this.loadingSnapshots = true;
				for (let i = 0; i < snapshots.length; i++) {
					const snapshot = snapshots[i];
					this.snapshots.push({
						id: snapshot.id,
						src: snapshot.uploadResult.signedUrl,
						thumbnail: snapshot.uploadResult.signedUrlThumbnail,
						User: this.getUserForSnapshot(snapshot),
						description: snapshot.notes ?? "",
						title: timezones.formatDate(snapshot.createdAt),
						createdAt: snapshot.createdAt,
						conversationId: snapshot.conversationId,
					});
				}
				this.$nextTick(() => {
					if (this.$route.query.artifactId && this.$route.query.artifactType) {
						const artifactType = this.$route.query.artifactType;
						const artifactId = this.$route.query.artifactId;
						const isPresent = this.snapshots.find(s => s.id === artifactId)
						if (artifactType == 'photo' && isPresent) {
							this.openLightBox(this.$route.query.artifactId)
						} else if (artifactType == 'photo' && !isPresent) {
							this.$root.$confirm.open("Error", "The media associated with this comment has been deleted.", {}, false, "OK", null, true, true, true);
						}
					}
				})
				this.loadingSnapshots = false;
			},
			loadRecordingsInsecure(artifacts) {
				this.recordingsLoading = true;
				this.recordings = artifacts?.sort((a, b) => (a.recordingStart < b.recordingStart ? 1 : -1));
				this.$nextTick(() => {
					if (this.$route.query.artifactId && this.$route.query.artifactType) {
						const artifactType = this.$route.query.artifactType;
						const artifactId = this.$route.query.artifactId;
						const isPresent = this.recordings.find(r => r.id === artifactId)
						if (artifactType == 'video' && isPresent) {
							this.openVideoLightBox(this.$route.query.artifactId)
						} else if (artifactType == 'video' && !isPresent) {
							this.$root.$confirm.open("Error", "The media associated with this comment has been deleted.", {}, false, "OK", null, true, true, true);
						}
					}
				})
				this.recordingsLoading = false;
				this.recordingCount = this.recordings.length;
				this.showIncompleteBannBearerer = this.shouldShowIncompleteBanner(this.recordings[0]);
			},
			adjustHeight() {
				document.body.style.height = `${window.innerHeight}px`;
			},
			async loadFilesInsecure(files) {
				this.loadingFiles = true;
				this.files = [];
				try {
					for (let i = 0; i < files.length; i++) {
						const file = files[i];
						const keySplits = file.uploadResult.Key.split(".");
						this.files.push({
							id: file.id,
							src: file.uploadResult.signedUrl,
							description: file.notes ?? "",
							title: file.uploadResult.metadata?.filename ?? timezones.formatDate(file.createdAt),
							extension: keySplits[keySplits.length - 1],
							createdAt: file.createdAt,
						});
					}
				} catch (error) {
					console.error(error);
				}
				this.loadingFiles = false;
			},
			validateTags() {
				// Only keep non-empty strings
				this.project.Labels = this.project.Labels.filter(label => typeof label !== 'string' || label.trim() !== '');
			},
			handleResize() {
				this.keyboardVisible = window.innerHeight < screen.height * 0.75;
				const slideGroup = this.$refs.slideGroup;
				if (!slideGroup) {
					return;
				}
				const mediaQuery = window.matchMedia('(orientation: portrait)');
				if (mediaQuery.matches) {
					slideGroup.scrollTo(this.selected);
				} else {
					const visibleIndex = Math.max(0, this.selected - 3);
					slideGroup.scrollTo(visibleIndex);
				}
			},
			remove(item) {
				this.project.Customers = this.project.Customers.filter((u) => u.id !== item.id);
			},
		},
		watch: {
			isLightboxThumbsOpen(newVal) {
				if (!newVal) {
					this.cancelBulkSelect('snapshots');
				}
			},
			selectedSnapshotIds: {
				handler() {
					if (this.selectedSnapshotIds.length > 0) {
						this.bulkSelect = true;
					} else {
						this.bulkSelect = false;
					}
				},
			},
			project: {
				handler() {
					this.checkDirty();
				},
				deep: true,
			},
			recordingId: {
				handler() {
					const recording = this.recordings.find((x) => x.id == this.recordingId);
					this.recordingConversationId = recording?.conversationId;
				},
			},
		},
		computed: {
			isMobile() {
				return this.$vuetify.breakpoint.smAndDown;
			},
			imageWidth() {
				return this.$vuetify.breakpoint.mdAndUp ? 190 : 132;
			},
			imageHeight() {
				return this.$vuetify.breakpoint.mdAndUp ? 130 : 83;
			},
			isFullScreen() {
				return document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitCurrentFullScreenElement;
			},
			isUnauthenticated() {
				return !this.me && !this.startingLoading;
			},
			accessType() {
				if (!this.isViewOnly) {
					return AccessType.Editor;
				}
				return AccessType.Viewer;
			},
			createdDate() {
				if (this.project) {
					const date = moment(this.project.createdAt).format("M/D/YYYY");
					return date;
				}

				return "";
			},
			showGalleryCloseButton() {
				return this.isLightboxThumbsOpen && window.innerWidth < 960;
			},
			timeZone() {
				const timeZone = this.me.user.timezone ?? moment.tz.guess();
				return timeZone;
			},
			customerName() {
				if (this.newSelectedCustomer?.firstname && this.newSelectedCustomer?.lastname) {
					return `${this.newSelectedCustomer?.firstname} ${this.newSelectedCustomer?.lastname}`;
				}

				if (this.newSelectedCustomer?.firstname) {
					return this.newSelectedCustomer?.firstname;
				}

				return this.newSelectedCustomer?.PhoneNumbers[0].phoneNumber;
			},
			customerPhone() {
				return this.newSelectedCustomer?.PhoneNumbers?.length > 0
					? this.newSelectedCustomer?.PhoneNumbers[0].phoneNumber
					: "";
			},
			projectItems() {
				return Projects.getProjectItems(this.projectSelectItems);
			},
			teamMemberItems() {
				return this.teamMembers.map((user) => {
					if (!user) {
						return {};
					}

					let name = "";

					if (user.firstname != null) {
						name = user.firstname;
					}

					if (user.lastname != null) {
						name = `${name} ${user.lastname}`;
					}

					let searchLabel = name;

					if (user.email) {
						searchLabel = `${searchLabel} ${user.email}`;
					}

					if (user.phone) {
						searchLabel = `${searchLabel} ${user.phone}`;
					}

					return Object.assign({}, user, { name, searchLabel });
				});
			},
			actionClass() {
				if (this.cropping) {
					return "cropping";
				} else if (this.annotating) {
					return "annotating";
				} else {
					return "";
				}
			},
		},
		beforeDestroy() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				window.removeEventListener("scroll", this.handleScroll);
			}
			// window.removeEventListener("resize", this.handleResize);
			// const inputs = this.$el.querySelectorAll("input, textarea");
			// inputs.forEach((input) => {
			// 	input.removeEventListener("focus", this.handleFocus);
			// 	input.removeEventListener("blur", this.handleBlur);
			// });
		},
		async mounted() {
			try {
				this.loading = true;
				this.startingLoading = true;
				this.loadTeamMembers();

				window.addEventListener("resize", this.handleResize);

				if (this.$vuetify.breakpoint.mdAndUp) {
					window.addEventListener("scroll", this.handleScroll);
				}
				
				eventBus.$on("ReloadPhotos", async (projectId) => {
					if (projectId === this.project.id) {
						const [snapshotsResp, filesResp] = await Promise.all([
							api.getArtifactsForProjectInsecure(this.project.id, "snapshots"),
							api.getArtifactsForProjectInsecure(this.project.id, "files"),
						]);
						this.loadSnapshotsInsecure(snapshotsResp.artifacts);
						this.loadFilesInsecure(filesResp.artifacts);
					}
				});
				eventBus.$on("UserRemovedFromProject", (projectShare, projectId) => {
					if (projectId === this.project.id) {
						this.loadTeamMembers();
					}
				});
				let projectShares;
				this.sharingId = this.shareId;
				if (this.$route.query.shareId) {
					this.sharingId = this.$route.query.shareId;
				}

				this.$router.onReady(async () => {
					let id = this.$route.params.id;
					this.isTouch = matchMedia("(hover: none), (pointer: coarse)").matches;
					if (!id) {
						console.debug("Id not available. Not loading");
						return;
					}

					//TODO + backlog item to fix this limitation of labels.

					try {
						this.me = await api.getMe();
						this.startingLoading = false;
						try {
							this.project = await api.getProject(id);
							this.projectUser = this.project.User;
							this.sharingId = this.project.shareId;
						} catch (err) {
							console.error(err);
						}
						projectShares = await api.getProjectShares({
							where: { projectId: id, userIdentifier: { $or: [this.me.user.email, this.me.account.id] } },
						});
						if (projectShares.rows.length > 0) {
							this.isAuthenticated = true;
							let isViewOnly = true;
							for (const projectShare of projectShares.rows) {
								if (projectShare.accessType === AccessType.Editor) {
									isViewOnly = false;
									break;
								}
							}
							this.isViewOnly = isViewOnly;
						} else {
							this.isAuthenticated = false;
							const output = await this.$root.$confirm.open("Heads Up!", 
							"The account associated with this email address does not have access to this project. Switch accounts to contribute to this project or continue as a viewer.", 
							null, false, "Switch Accounts", "Continue as view only", true);
							if (output) {
								StorageWrapper.setItem("login-path", this.$route.fullPath);
								// logout
								this.$auth.logout({
									returnTo: window.location.protocol + "//" + window.location.host + "/logout",
								});
							}
						}
					} catch (err) {
						console.error(err);
						this.startingLoading = false;
						if (this.sharingId) {
							this.isViewOnly = true;
						} else {
							this.$root.$confirm.open("Error", err.message, {}, false, "OK", null, true, true, true);
							Sentry.captureException(err, {
								tags: {
									method: "mounted",
									file: "ProjectPage",
								},
							});
						}
					}
					this.recordingsLoading = true;
					this.loadingFiles = true;
					this.loadingSnapshots = true;
					const [recordingsResp, snapshotsResp, filesResp] = await Promise.all([
						api.getArtifactsForProjectInsecure(id, "recordings"),
						api.getArtifactsForProjectInsecure(id, "snapshots"),
						api.getArtifactsForProjectInsecure(id, "files"),
					]);
					this.conversations = snapshotsResp.conversations;
					this.recordingConversations = recordingsResp.conversations;
					this.loadSnapshotsInsecure(snapshotsResp.artifacts);
					this.loadRecordingsInsecure(recordingsResp.artifacts);
					this.loadFilesInsecure(filesResp.artifacts);
					if (!this.isAuthenticated && !this.sharingId) {
						if (this.$route.params.sharingId) {
							this.sharingId = this.$route.params.sharingId;
						}
						else {
							this.$router.push("/projects");
						}
					}
					if (this.isAuthenticated) {
						if (!this.project) {
							console.warn(`Unable to load project: Id=${id}`);
							return;
						}
						// this.removeInternalPlaceholder();
						if (this.project.GeoAddress) {
							this.address = this.project.GeoAddress.formattedAddress;
						}
						this.address = this.address.trim();
						this.sourceProject = JSON.parse(JSON.stringify(this.project));

						const customers = this.project.Customers;
						if (customers && customers.length > 0) {
							this.newSelectedCustomer = customers[0];
						}
						this.newSelectedProject = this.project.Project;
						if (this.newSelectedCustomer) {
							this.customerSelectItems.push(this.newSelectedCustomer);
						}

						this.hasFreeTrialEnded = await hasFreeTrialEnded(this.me.organization);
						this.isProjectOwner = this.me.user.id === this.project.createdBy;
						if (this.hasFreeTrialEnded) {
							this.isViewOnly = true;
							this.shouldShowProjectActionFAB = false;
						}
						await this.loadLabelOptions();
					} else {
						this.project = await api.getProjectInsecure(id, this.sharingId);
						if (!this.project) {
							console.warn(`Unable to load project: Id=${id}`);
							return;
						}
						if (this.project.GeoAddress) {
							this.address = this.project.GeoAddress.formattedAddress;
						}
						this.address = this.address.trim();					
					}

					this.loading = false;
				});
			} catch (err) {
				console.error(err);
				this.$root.$confirm.open("Error", err.message, {}, false, "OK", null, true, true, true);
				Sentry.captureException(err, {
					tags: {
						method: "mounted",
						file: "ProjectPage",
					},
				});
			}

			this.$watch(
				() => this.$refs.lightBox?.isLightboxThumbsOpen,
				(newVal) => {
					this.isLightboxThumbsOpen = newVal;
				},
			);
		},
	};
</script>
<style scoped lang="scss">
	@media (min-width: 960px) {
		.pad-equal {
			padding: 16px 0px !important;
		}
	}
	.container .page-content {
		max-width: unset !important;
	}
	.content {
		min-height: 100vh; /* Ensures the content area is at least as tall as the viewport */
		display: flex;
		flex-direction: column;
	}
	.comment-controls.keyboard-visible {
		top: 0; /* Adjusts to stay in view when keyboard is visible */
		height: auto; /* Allow height to adjust with content */
	}
	.border-topleft {
		border-radius: 16px 0px 0px 0px !important;
		height: 100%;
		padding: 16px 8px;
	}
	.full-width {
		width: 100%;
	}
	.overflow-hidden {
		overflow: hidden;
	}
	.sticky-header {
		position: sticky;
		top: 0;
		padding-top: 0px;
		padding-bottom: 0px;
		z-index: 60;
		background-color: white; 
	}

	:deep(.v-slide-group__prev) {
		display: flex !important;
	}
	:deep(.v-slide-group__next) {
		display: flex !important;
	}
	:deep(.v-slide-group__prev--disabled) {
		display: none !important;
	}
	:deep(.v-slide-group__next--disabled) {
		display: none !important;
	}

	.page-content {
		border-radius: 12px 0px 0px 0px;
		padding: 16px;
	}
	@keyframes slide-up {
		from {
			transform: translateY(100%);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.slide-up-enter-active {
		animation: slide-up 0.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
	}

	.no-margin {
		margin: 0px;
	}
	.align-top {
		align-items: flex-start;
	}
	.comment-controls {
		position: fixed;
		width: 30%;
		height: 75vh;
		top: 12.5vh;
		right: 3%;
		display: flex;
		flex-direction: column;
		z-index: 10000;
	}
	.selection-icon {
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;
		z-index: 20000;
	}
	.danger-zone {
		display: none;
		width: 100%;
		background-color: #fff;
	}

	.danger-zone i {
		font-size: 24px;
		margin-left: -8px;
	}

	.v-btn:not(.v-btn--round).v-size--default.danger-zone {
		height: 42px;
	}

	.project-recordings > div:first-child {
		margin-bottom: 20px;
	}

	.snapshot-controls {
		display: flex;
		justify-content: space-between;
		min-height: 68px;
	}

	.snapshot-controls i,
	.close-gallery i {
		font-size: 18px;
	}

	.close-gallery {
		position: fixed;
		right: 8px;
		top: 8px;
		z-index: 20000;
	}

	.close-gallery > button {
		color: #fff !important;
		background-color: #000 !important;
	}

	.selected {
		background-color: green !important;
		opacity: 1 !important;
		border-color: green !important;
	}

	.select-overlay::v-deep {
		transition: none;
	}

	.select-overlay::v-deep > .v-overlay__content {
		position: absolute;
		bottom: 9px;
		right: 9px;
	}

	.select-overlay::v-deep i {
		font-size: 18px;
	}

	.select-overlay:hover::v-deep {
		border: #90baff solid 4px;
	}

	.select-overlay.selected::v-deep {
		color: #0070ff;
		border: #0070ff solid 4px;
	}

	.select-overlay:hover::v-deep > .v-overlay__content {
		bottom: 5px;
		right: 5px;
	}

	.select-overlay.selected::v-deep > .v-overlay__content {
		bottom: 5px;
		right: 5px;
	}

	.project-page::v-deep .v-snack.v-snack--active.v-snack--has-background.v-snack--top.v-snack--vertical {
		z-index: 10000;
	}

	/* .status-input {
		width: 200px;
	} */

	/* this section is when the sections stack */
	@media (max-width: 1264px) {
		.project {
			display: flex;
			flex-direction: column-reverse;
		}

		.project > div:not(:first-child) {
			padding-bottom: 0px;
		}
	}

	.project-mobile {
		display: none;
	}

	.recording-buttons {
		display: flex;
		justify-content: space-between;
	}

	.about-title {
		display: flex;
		justify-content: space-between;
	}

	#join-call {
		padding: 0 8px;
	}

	.height-control {
		height: fit-content !important;
	}

	.photo-container {
		height: 130px;
	}

	.document-container {
		height: 140px;
	}

	.copy-recording.v-btn--has-bg,
	.copy-invite.v-btn--has-bg {
		background-color: #fff;
		color: #0070ff;
		border: 1px solid #0070ff;
		flex: 1;
	}

	.file-icon-wrapper {
		position: relative;
		cursor: pointer;
	}
	.file-icon {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
	}

	.file-icon-selected {
		border: 1px solid blue !important;
	}

	.v-tab--active {
		color: #0070ff !important;
	}

	.v-tab {
		font-weight: bold !important;
	}

	.copy-invite.v-btn--has-bg {
		max-width: 270px;
		display: block;
	}

	.copy-recording i,
	.copy-invite i {
		font-size: 18px;
	}

	.v-tab {
		background-color: #f5f5f5;
	}

	.delete-recording.v-btn--has-bg {
		background-color: #fff;
		color: #ec5d43;
		border: 1px solid #ec5d43;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		font-size: 18px;
		padding-right: 20px;
	}

	.take-snapshot.v-btn--has-bg {
		background-color: #fff;
		color: #0070ff;
		border: 1px solid #0070ff;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		font-size: 18px;
		padding-right: 20px;
	}

	.project-card {
		margin-bottom: 8px;
	}

	.view-button {
		height: 22px !important;
		width: 61px !important;
		font-size: 12px !important;
		border-radius: 8px;
	}

	.minimize-height {
		margin-top: -10px !important;
	}

	.v-input.v-input--hide-details {
		margin-top: -15px;
		margin-bottom: 29px;
	}

	.project-recording {
		object-fit: contain;
		background-color: #0000000a;
	}

	.project-type i {
		font-size: 18px;
	}

	.recording-carousel {
		margin-bottom: 5px;
	}

	.recording-carousel.recording-incomplete {
		height: 100% !important;
	}

	.recording-carousel.recording-incomplete::v-deep .v-carousel__item {
		height: 100% !important;
	}

	.recording-pagination::v-deep button {
		box-shadow: none;
	}

	.recording-pagination::v-deep button.v-pagination__item--active {
		background-color: #ebeef5 !important;
		color: rgba(0, 0, 0, 0.87);
	}

	.created {
		color: #a6a6a6;
		margin-bottom: 10px;
	}

	.created > div:not(:first-child) {
		font-size: 18px;
	}

	.media-header .v-icon {
		width: 28px;
		height: 28px;
		color: #0070ff;
		margin-right: 8px;
		padding: 6px;
	}

	.copy-recording-link.v-btn {
		width: 40px;
		height: 40px;
		min-width: 40px;
		padding: 0;
		border: 1px solid #fff;
		margin-right: 10px;
	}

	.border-btn {
		border: 1px solid black;
		margin-right: 10px;
		border-color: black !important;
	}

	.copy-recording-link.v-btn.copied {
		background-color: rgba(212, 231, 255, 1);
		border-color: rgba(212, 231, 255, 1);
	}

	.copy-recording-link.v-btn.copied i {
		color: rgba(0, 112, 255, 1);
	}

	.copy-recording-link i {
		color: #fff;
		font-size: 24px;
		margin-left: -7px;
	}

	.copied-bubble {
		background-color: #fff;
		color: #000;
		transform: translateX(10%) !important;
	}

	.status-pill {
		display: flex;
		padding: 0.5rem;
		align-items: center;
		justify-content: center;
		font-size: 0.8rem;
		font-weight: 500;
		line-height: 20;
		height: 25px;
		border-radius: 5px;

		cursor: pointer;
	}

	.add-item-button {
		position: fixed;
		left: 0;
		justify-content: center;
		z-index: 20;
		bottom: 3.5rem;
		background-color: #fff;
		width: 100vw;
	}

	.add-item-button button {
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
	}

	.not-started {
		background: rgba(255, 70, 11, 0.16);
	}

	.in-progress {
		background: rgba(255, 184, 0, 0.16);
	}

	.completed {
		background: rgba(2, 181, 85, 0.16);
	}

	@media (max-width: 320px) {
		.recording-buttons > button:first-child {
			font-size: 12px;
		}

		.snapshots.fixed > .snapshot-controls {
			width: 85%;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child > button {
			width: 100%;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child > button:not(:first-child) {
			margin-left: 0;
			margin-top: 15px;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child {
			margin-bottom: 8px;
		}
	}

	.v-card.page {
		margin-left: 0% !important;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		.project-details {
			margin-top: 20px;
		}

		.comment-controls {
			width: 100vw;
			right: 0;
			height: 90%;
			bottom: 0;
			top: unset !important;
		}
		.desktop-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			padding: 0 !important;
			border-radius: 0px 0px 0px 0px !important;
		}
		.danger-zone {
			display: block;
		}

		.project-card.v-sheet.v-card:not(.v-sheet--outlined) {
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.28);
			border-radius: 12px;
		}

		.project-page .row .col-md-12 {
			padding-bottom: 3px;
		}

		.v-main__wrap {
			margin-right: 0px;
		}

		.project-page {
			background-color: #f5f5f5;
		}

		.edit-title {
			display: none;
		}

		.project-mobile {
			display: block;
			width: 100%;
			text-align: center;
		}

		.project-mobile > div:first-child {
			display: flex;
			align-content: center;
			justify-content: space-between;
		}

		.project-type {
			font-size: 14px;
		}

		.project-mobile > div:first-child > span:first-child {
			cursor: pointer;
		}

		.project-mobile > div:first-child > span:last-child {
			opacity: 0;
		}
	}

	.video-title {
		justify-content: space-between;
	}

	.invite-links {
		display: flex;
	}

	.invite-links > button {
		margin-left: 16px;
		align-self: flex-end;
	}

	.document {
		width: 100px;
		height: 80px;
		background-color: #f0f0f0;
		border: 2px solid #ccc;
		border-radius: 3px;
		margin-right: 2px;
	}

	.document-edge {
		width: 0px;
		height: 0px;
		border-top: 20px solid #ccc;
		border-right: 20px solid transparent;
		top: -2px;
		right: -2px;
	}

	.document-content {
		min-width: 40px !important;
		text-align: center;
	}

	.file-type {
		font-size: 10px;
		max-width: 100px;
		font-weight: bold;
		color: #666;
		white-space: wrap;
		line-height: 1.375;
		word-break: break-word;
	}

	.menu {
		justify-content: space-evenly;
		margin-bottom: 28px;
	}

	.menu .v-btn {
		height: 44px;
		width: 44px;
		border: 2px solid var(--primary-project-icon);
	}

	.menu .v-btn .v-icon {
		font-size: 1.75rem;
		color: var(--primary-project-icon);
	}

	.menu > .menu-action {
		width: 82px;
		align-items: center;
		padding: 8px;
	}

	.menu > .menu-action div:nth-child(2),
	.menu > .menu-action .text {
		margin-top: 8px;
		width: 60px;
		font-size: 0.625rem;
		font-weight: 600;
		text-align: center;
	}
	
	/* this section is when a data table goes to mobile view */
	@media (max-width: 599px) {
		.invite-links {
			display: block;
		}

		.invite-links > button {
			margin-left: 0;
			margin-top: 16px;
		}
	}

	@media (max-width: 375px) {
		.recording-buttons > button:first-child {
			font-size: 12px;
		}
	}
</style>
<style>
	#map::placeholder {
		color: transparent !important;
	}
	:deep(.container.align-stretch.shell.container--fluid.fill-height.no-padding) {
		padding-top: 20px !important;
		padding: 0 !important;
	}

	.cool-lightbox-toolbar > button:first-child {
		display: none;
	}

	.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn:nth-child(2) {
		margin-left: 20px;
	}

	.cool-lightbox-toolbar:has(> button:only-child) {
		justify-content: flex-end;
	}

	.cool-lightbox-toolbar > button:only-child {
		display: block;
	}

	.snapshot-description {
		min-width: 270px;
		height: 40px;
		border-radius: 8px;
		padding: 10px;
	}

	.submit-caption-btn {
		margin-left: 10px;
		padding: 0px 32px;
		background-color: #0070ff !important;
		color: white;
		height: 40px;
		border-radius: 8px;
	}

	.submit-caption-btn.disabled {
		opacity: 0.65;
		background-color: lightgrey !important;
		color: rgba(0, 0, 0, 0.26) !important;
	}

	img.loaded {
		display: block;
		max-width: 100%;
	}

	.crop-img-btn {
		margin-right: 16px;
		border: thin solid white !important;
		color: white !important;
		padding: 0px 32px;
		height: 40px;
		border-radius: 8px;
	}

	.clear {
		height: auto !important;
		width: auto !important;
	}

	.lightbox-controls {
		position: fixed;
		width: 70%;
		display: flex;
		justify-content: center;
		z-index: 10000;
		top: 0;
		left: 15%;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon,
	.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn,
	.cool-lightbox__navigation > button.cool-lightbox-button > div.cool-lightbox-button__icon {
		color: #fff;
		background-color: #000;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		margin: 10px 3px;
	}

	.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
		right: 0px;
		padding: 0;
	}

	.cool-lightbox.annotating {
		pointer-events: none;
	}
	.lightbox-controls > button.theme--light.v-btn.v-btn--icon:last-child {
		color: #ec5d43;
	}

	.lightbox-controls > .v-btn--icon .v-icon {
		/* font-size: 22px !important; */
		/* margin-left: 0px !important; */
	}

	.lightbox-controls i::before {
		/* margin-left: 0px; */
	}

	.lightbox-tooltip {
		background-color: #212121;
		font-size: 10px;
		line-height: 12px;
		padding: 5px 8px;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		left: 0;
	}

	.cool-lightbox .cool-lightbox__inner {
		left: 0;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox__inner {
		left: 280px;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		width: 280px;
		background-color: #000;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
		left: calc(10% + 280px);
		width: calc(80% - 280px);
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list {
		display: none;
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:before {
		border-color: rgba(0, 112, 255, 1);
	}

	.cool-lightbox.annotating .cool-lightbox-caption,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox__navigation {
		display: none;
	}

	/* .snapshots.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10010;
		width: 280px;
		height: 100vh;
		overflow-y: scroll;
	} */

	.snapshots.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.snapshots.fixed > .snapshot-controls {
		flex-direction: column;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		.snapshots.fixed {
			width: 100%;
			z-index: 10009;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
			width: 100%;
			z-index: 100019;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
			display: none;
		}

		.crop-controls {
			width: 80% !important;
			left: 10% !important;
		}

		.annotation-controls {
			width: calc(100vw - 10%);
			left: 5%;
			padding: 10px;
		}

		.annotation-controls .v-overlay {
			margin: 10px !important;
		}
	}

	#incomplete-message {
		color: red;
		font-size: 12px;
		margin-top: 12px;
	}

	#incomplete-message > div.v-alert {
		background-color: #faeef0 !important;
		border-color: #e33154 !important;
		color: #e33154 !important;
		border-radius: 8px;
		border: none !important;
		font-weight: 500;
		margin-bottom: 8px;
	}

	.v-alert__border.v-alert__border--left {
		opacity: 1;
	}

	/* New */

	.number-container {
		color: #03011d99;
		align-items: center;
		justify-content: space-between;
		padding: 4px 8px;
	}

	.wide {
		padding-bottom: 1rem;
	}

	.number-container > .hashtag {
		width: 20px;
		height: 20px;
		font-size: 1.25rem;
	}

	.number-container > .number {
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 1.3;
	}

	.number-container > .round-spacer {
		width: 4px;
		height: 4px;
		font-size: 1px;
		justify-content: center;
		align-content: center;
		align-items: center;
	}

	.number-container > .date {
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.2;
		letter-spacing: 0.12px;
		text-transform: uppercase;
	}

	.page-actions {
		justify-content: flex-end;
	}

	.page-actions.mobile {
		display: none !important;
	}

	.page-actions .v-btn {
		min-width: 120px;
		width: 120px;
		margin-bottom: 1rem;
	}

	.menu {
		justify-content: space-evenly !important;
		margin-top: 28px;
	}

	.menu .v-btn {
		height: 44px;
		width: 44px;
		border: 2px solid #0070ff;
	}

	.menu .v-btn .v-icon {
		font-size: 1.75rem;
		color: #0070ff;
	}

	.menu > .menu-action {
		width: 82px;
		align-items: center;
		padding: 8px;
	}

	.menu > .menu-action div:nth-child(2),
	.menu > .menu-action .text {
		margin-top: 8px;
		width: 50px;
		font-size: 0.625rem;
		font-weight: 500;
		text-align: center;
	}

	.menu-small {
		justify-content: space-evenly !important;
	}

	.menu-small .v-btn {
		height: 32px;
		width: 32px;
		border: 2px solid #0070ff;
	}

	.menu-small .v-btn .v-icon {
		font-size: 1.2rem;
		color: #0070ff;
	}

	.menu-small > .menu-action {
		width: 45px;
		align-items: center;
	}

	.menu-small > .menu-action .v-btn {
		width: 32px;
		height: 32px;
		align-items: center;
	}

	.menu-small > .menu-action .v-btn > .v-btn__content {
		width: 10px;
		height: 10px;
	}

	.menu-small > .menu-action div:nth-child(2),
	.menu-small > .menu-action .text {
		margin-top: 8px;
		width: 30px;
		font-size: 0.5rem;
		font-weight: 600;
		text-align: center;
	}

	.project-row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.project-row .col-12:first-child {
		padding-right: 12px;
	}

	.project-row .col-12:last-child {
		padding-left: 8px;
	}

	.is_unauthenticated {
		padding-left: 12px !important;
		padding-right: 12px !important;
	}

	.v-select__selections {
		min-height: unset !important;
	}

	.v-card {
		text-align: left !important;
	}

	.media-card {
		margin-bottom: 1rem;
		min-height: unset !important;
		border-radius: 15px !important;
	}

	.media-header {
		align-items: center;
		margin-bottom: 16px;
		color: #03011d;
	}

	.media-header.v-icon {
		width: 28px;
		height: 28px;
		color: #0070ff;
		/* margin-left: 12px; */
		margin-right: 8px;
		padding: 6px;
	}

	.media-header .heading {
		font-family: "Inter Tight";
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.2;
		margin-right: 4px;
	}

	.video-processing {
		display: flex;
		height: 400px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-radius: 12px 0px 0px 0px;
		border: 2px solid var(--ls-contact-theme-space-cadet-8, rgba(3, 1, 29, 0.08));
		background: var(
			--Video-Processing-Gradient,
			linear-gradient(254deg, #f7f5fe 0%, #f0f8ff 13.76%, #ebfffa 43.55%, #eaf9ff 76.6%, #fef5fd 99.13%)
		);
	}

	.video-processing .message {
		margin: 20px 0 !important;
	}

	.video-processing > .refresh > .v-btn {
		background-color: #03011d80 !important;
		color: #fff !important;
	}

	.video-player-container .date {
		justify-content: center;
		margin-top: 16px;
		text-transform: uppercase;
		color: #03011d66;
		cursor: default;
		scroll-behavior: auto;
	}

	.video-player {
		margin: 5px;
		height: 82px;
		/* min-width: 132px;
		max-width: 132px; */
	}

	.video-actions {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100;
	}

	.video-actions .v-btn:first-child {
		margin: 0.625rem 0.625rem 0.5rem 0;
	}

	.video-actions .v-btn:nth-child(2) {
		margin-right: 0.625rem;
	}

	.snapshot-actions {
		justify-content: space-between;
	}

	.snapshot-actions .selection {
		/* margin-left: 12px; */
	}

	.snapshot-actions .selection .v-btn {
	}

	.snapshot-actions .selection .v-btn:first-child {
		margin-right: 12px;
	}

	.snapshot-actions .actions {
		margin-right: 12px;
	}

	.snapshot-actions .actions .v-btn {
		background: none;
	}

	.snapshot-actions .actions .v-btn:first-child {
		margin-right: 1rem;
	}

	.snapshots .col-6:nth-child(odd) {
		padding: 0 4px 4px 0;
	}

	.snapshots .col-6:nth-child(even) {
		padding: 0 0 4px 4px;
	}

	.snapshots .snapshot-thumbnail {
		cursor: pointer;
	}

	.header-name {
		font-family: "Inter Tight";
		font-size: 1.8rem;
		font-style: normal;
		font-weight: 600;
		line-height: 132%;
	}

	.more-actions .resend-invite .v-icon {
		color: #0070ff;
	}

	.more-actions .rejoin-project .v-icon {
		color: #0070ff;
	}

	.more-actions .delete-project .v-icon {
		color: #f94366;
	}

	.video-column-md {
		width: 43rem;
	}

	@media (max-width: 959px) {
		.page-actions .v-btn {
			width: 50%;
		}

		.page-actions.mobile {
			display: flex !important;
		}

		.page-actions.desktop {
			display: none;
		}

		.row .video-column:nth-child(1) {
			/* order: 2; */
		}

		.row .video-column:nth-child(2) {
			/* order: 1; */
		}

		.header-name {
			font-size: 1rem;
			line-height: unset;
		}

		.project-row .col-12:first-child {
			padding-right: 12px;
		}

		.v-card.page.media-card {
			padding: 0;
		}

		.v-card.page.media-card .v-card__text {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.v-card.project-card.media-card {
			padding: 16px 8px;
		}

		.v-card.project-card.media-card .v-card__text.no-snapshots {
			padding: 20px;
		}
		:deep(.v-card__text.pad-equal) {
			padding: 0px 16px !important;
		}

		.video-processing {
			margin: 0;
			padding: 0 12px;
		}
	}

	@media (max-width: 1200px) {
		.page-actions {
			justify-content: flex-start;
		}
	}

	.left-move {
		padding-left: 12px !important;
	}

	.header-name input {
		max-height: 40px !important;
	}

</style>
<style scoped>
	.chip-text {
		max-width: 300px !important;
	}

	.custom-padding {
		padding-left: 2px !important;
	}
</style>